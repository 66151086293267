/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
import Label from "./Label";
import Picker from "./Picker";
import Close from "./Close";
export default function Mobilemenu(props) {
  const {
    onClose,
    slot,
    profileLabel,
    onProfileClick,
    registerDisplay,
    currencyLabel,
    countryLabel,
    flagplaceholder,
    onRegisterClick,
    onAboutUsClick,
    onFaqClick,
    onContactUsClick,
    onWishlistClick,
    onCurrencyClick,
    icon,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="16px"
      direction="row"
      width="600px"
      height="unset"
      justifyContent="space-between"
      alignItems="flex-start"
      position="relative"
      padding="24px 16px 24px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "Mobilemenu")}
      {...rest}
    >
      <Flex
        gap="32px"
        direction="column"
        width="283px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 32px 0px 32px"
        {...getOverrideProps(overrides, "Sidebar")}
      >
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "section4417912")}
        >
          <Flex
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 322")}
          >
            <Flex
              gap="0"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              borderRadius="4px"
              padding="6px 6px 6px 6px"
              backgroundColor="rgba(255,220,122,1)"
              children={icon}
              {...getOverrideProps(overrides, "icon")}
            ></Flex>
            <Text
              fontFamily="Montserrat"
              fontSize="14px"
              fontWeight="400"
              color="rgba(106,51,22,1)"
              lineHeight="21px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Home"
              {...getOverrideProps(overrides, "label4417916")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="207px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          children={slot}
          {...getOverrideProps(overrides, "Section4417917")}
        ></Flex>
        <Flex
          gap="16px"
          direction="column"
          width="97px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Section4417981")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="700"
            color="rgba(61,12,0,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Info"
            {...getOverrideProps(overrides, "label4417982")}
          ></Text>
          <Label
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 16px 0px 16px"
            state="Default"
            label="About Us"
            as="a"
            href="/#aboutUs"
            onClick={onAboutUsClick}
            {...getOverrideProps(overrides, "label48611745")}
          ></Label>
          <Label
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 16px 0px 16px"
            state="Default"
            label="FAQs"
            as="a"
            href="/faq"
            onClick={onFaqClick}
            {...getOverrideProps(overrides, "label48611748")}
          ></Label>
          <Label
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 16px 0px 16px"
            state="Default"
            label="Contact Us"
            as="a"
            href="/contactus"
            onClick={onContactUsClick}
            {...getOverrideProps(overrides, "label48611750")}
          ></Label>
        </Flex>
        <Flex
          gap="32px"
          direction="column"
          width="224px"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "section44171806")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="700"
            color="rgba(61,12,0,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="My Account"
            {...getOverrideProps(overrides, "label44171807")}
          ></Text>
          <Label
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 16px 0px 16px"
            state="Default"
            label="Wishlist"
            as="a"
            href="/account/wishlist"
            onClick={onWishlistClick}
            {...getOverrideProps(overrides, "label48611752")}
          ></Label>
          <Flex
            gap="16px"
            direction="column"
            width="224px"
            height="unset"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Buttons")}
          >
            <Button
              width="unset"
              height="unset"
              label="Sign In"
              shrink="0"
              alignSelf="stretch"
              size="small"
              isDisabled={false}
              variation="primary"
              children={profileLabel}
              onClick={onProfileClick}
              {...getOverrideProps(overrides, "Button44171809")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              alignSelf="stretch"
              size="small"
              isDisabled={false}
              variation="default"
              display={registerDisplay}
              children="Register"
              onClick={onRegisterClick}
              {...getOverrideProps(overrides, "Button44171810")}
            ></Button>
          </Flex>
        </Flex>
        <Flex
          gap="32px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "section44181071")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="700"
            color="rgba(61,12,0,1)"
            lineHeight="21px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Region"
            {...getOverrideProps(overrides, "label44181070")}
          ></Text>
          <Picker
            display="flex"
            gap="8px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 16px 0px 16px"
            countryLabel={countryLabel}
            currencyLabel={currencyLabel}
            flagplaceholder={flagplaceholder}
            onClick={onCurrencyClick}
            {...getOverrideProps(overrides, "Picker")}
          ></Picker>
        </Flex>
      </Flex>
      <Close
        width="24px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={onClose}
        {...getOverrideProps(overrides, "close")}
      ></Close>
    </Flex>
  );
}
