import React, { useEffect, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import ProductOptionsStyle from './ProductOptions.module.scss';
import ResultListItemStyle from './../../../search/components/ResultListItem/ResultListItem.module.scss';
import { format } from 'layout/util/moneyUtils';
import classNames from 'classnames';

const ProductOptions = ({
  additionalSkus,
  selectedSku,
  onSkuChange,
  isInProductCard,
  allOrderItems,
  editMode,
  quantity
}) => {

  if (isEmpty(additionalSkus)) {
    return null;
  }

  let quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == selectedSku?.id)?.quantity;
      if (quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined) {
        quantityOfThisSkuInCart = 0;
      }

  if (!selectedSku || (selectedSku.productQuantityAvailable <= quantityOfThisSkuInCart && selectedSku.inventoryType == 'CHECK_QUANTITY' && !editMode)) {
    for (let i = 0; i < additionalSkus.length; i++) {
      let quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == additionalSkus[i]?.id)?.quantity;
      if (quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined) {
        quantityOfThisSkuInCart = 0;
      }
      if (additionalSkus[i].available && (additionalSkus[i].inventoryType !== 'CHECK_QUANTITY' || quantityOfThisSkuInCart < additionalSkus[i].productQuantityAvailable)) {
        onSkuChange(additionalSkus[i]);
        break;
      }
    }
  }

  const updateSku = (option) => {
    selectedSku = option;
    onSkuChange(selectedSku);
  };

  return (
    <div
      className={classNames(
        ProductOptionsStyle.Product_option,
        !isInProductCard && additionalSkus && additionalSkus.length === 1
          ? ProductOptionsStyle.Product_option_one_sku_not_card
          : ''
      )}
    >
      {additionalSkus.map((option) => {
        let quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == option?.id)?.quantity;
        if (quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined) {
          quantityOfThisSkuInCart = 0;
        }
        let exists = quantityOfThisSkuInCart > 0;

        return (
          <div key={option.id} className={classNames(ProductOptionsStyle.Product_option_box)}>
            <button
              disabled={
                !option.available ||
                (option.id !== selectedSku?.id && exists && editMode) ||
                (option.id !== selectedSku?.id &&
                  quantityOfThisSkuInCart >= option.productQuantityAvailable &&
                  option.inventoryType === 'CHECK_QUANTITY' &&
                  !editMode)
              }
              className={classNames(
                selectedSku && selectedSku.id === option.id
                  ? ProductOptionsStyle.Button__selected
                  : ProductOptionsStyle.Button__not_selected,
                isInProductCard ? ProductOptionsStyle.Button_Font_Small : ''
              )}
              onClick={() => updateSku(option)}
            >
              {option.salePrice !== null && format(option.salePrice) !== format(option.retailPrice) ? (
                <>
                  <span className={ResultListItemStyle.retailPriceLineThrough}>
                    {format(option.retailPrice)}
                  </span>
                  <span>
                    {format(option.salePrice)}/{option.productOptionsValue}
                  </span>
                </>
              ) : (
                <span>
                  {format(option.retailPrice)}/{option.productOptionsValue}
                </span>
              )}
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default ProductOptions;
