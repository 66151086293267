// MyAmplifyButton.js
import React, { useState, useEffect } from 'react';
import { Button } from '@aws-amplify/ui-react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const AmplifyTemporalButton = ({ to,onClick, children }) => {
  const [isLargeScreen, setIsLargeScreen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 600);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Link to={to} onClick={onClick}>
      <Button className={
        classNames(
            'my-amplify-button',
            'amplify-button',
            'amplify-field-group__control',
            'amplify-button--primary',
            isLargeScreen ? 'amplify-button--default' : 'amplify-button--small'
          )
      }>
        {children}
      </Button>
    </Link>
  );
};

export default AmplifyTemporalButton;
