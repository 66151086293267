/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function IndianSpicescontentmobile(props) {
  const {
    image,
    leftArrow,
    rightArrow,
    firstDot,
    secondDot,
    thirdDot,
    onLeftClick,
    onRightClick,
    sliderImage,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="16px"
      direction="column"
      width="288px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "IndianSpicescontentmobile")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content52311731")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="700"
          color="rgba(21,71,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.85px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Indian Spices(Coming Soon)"
          {...getOverrideProps(overrides, "Indian Spices(Coming Soon)")}
        ></Text>
        <Text
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="500"
          color="rgba(21,71,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="1.05px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Our range of whole and powdered spices is carefully sourced and hygienically cleaned to preserve their natural essence and purity."
          {...getOverrideProps(
            overrides,
            "Our range of whole and powdered spices is carefully sourced and hygienically cleaned to preserve their natural essence and purity."
          )}
        ></Text>
      </Flex>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="443px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Container")}
      >
        <Flex
          gap="8px"
          direction="column"
          width="288px"
          height="469px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Bottom")}
        >
          <Flex
            gap="24px"
            direction="column"
            width="unset"
            height="439px"
            justifyContent="flex-start"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Content52311736")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="290px"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 501")}
            >
              <Flex
                gap="8px"
                direction="column"
                width="216px"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="8px 8px 8px 8px"
                {...getOverrideProps(overrides, "Image52311738")}
              >
                <Image
                  width="200px"
                  height="188.41px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={image}
                  {...getOverrideProps(overrides, "Image52311739")}
                ></Image>
              </Flex>
              <Flex
                gap="72px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="8px 8px 8px 8px"
                children={sliderImage}
                {...getOverrideProps(overrides, "SliderImage")}
              ></Flex>
              <Flex
                gap="20px"
                direction="row"
                width="285px"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311741")}
              >
                <Image
                  width="32px"
                  height="22px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={firstDot}
                  {...getOverrideProps(overrides, "Image52311742")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="225px"
                  height="21px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Rich in flavor & aroma."
                  {...getOverrideProps(overrides, "Rich in flavor & aroma.")}
                ></Text>
              </Flex>
              <Flex
                gap="20px"
                direction="row"
                width="285px"
                height="38px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311744")}
              >
                <Image
                  width="32px"
                  height="33px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={secondDot}
                  {...getOverrideProps(overrides, "Image52311745")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="225px"
                  height="19px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Single origin spices."
                  {...getOverrideProps(overrides, "Single origin spices.")}
                ></Text>
              </Flex>
              <Flex
                gap="20px"
                direction="row"
                width="285px"
                height="53px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311747")}
              >
                <Image
                  width="32px"
                  height="35px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={thirdDot}
                  {...getOverrideProps(overrides, "Image52311748")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="230px"
                  height="44px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Finest quality & unadulterated."
                  {...getOverrideProps(
                    overrides,
                    "Finest quality & unadulterated."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="29px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Button52311750")}
              >
                <Button
                  width="unset"
                  height="unset"
                  shrink="0"
                  size="small"
                  isDisabled={true}
                  variation="primary"
                  children="Browse Indian Spices"
                  {...getOverrideProps(overrides, "Button54181588")}
                ></Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
