/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function Snackscontentmobile(props) {
  const {
    image,
    leftArrow,
    rightArrow,
    firstDot,
    secondDot,
    thirdDot,
    fourthDot,
    madeInIndiaImage,
    onLeftClick,
    onRightClick,
    sliderImage,
    overrides,
    ...rest
  } = props;
  const buttonFiveFourOneSevenOneSevenTwoTwoOnClick = useNavigateAction({
    type: "url",
    url: "/savoury",
  });
  const buttonFiveFourOneSevenOneSevenThreeOneOnClick = useNavigateAction({
    type: "url",
    url: "/sweets",
  });
  return (
    <Flex
      gap="16px"
      direction="column"
      width="288px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Snackscontentmobile")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content52311671")}
      >
        <Flex
          gap="8px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 513")}
        >
          <Text
            fontFamily="Montserrat"
            fontSize="20px"
            fontWeight="700"
            color="rgba(21,71,38,1)"
            lineHeight="54px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            letterSpacing="0.85px"
            width="81px"
            height="31px"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Snacks"
            {...getOverrideProps(overrides, "Snacks")}
          ></Text>
          <Image
            width="79px"
            height="36.24px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            objectFit="cover"
            src={madeInIndiaImage}
            {...getOverrideProps(overrides, "Vector 2")}
          ></Image>
        </Flex>
        <Text
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="500"
          color="rgba(21,71,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="1.05px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Our snacking range has artisan sweets and savory treats made with wholesome ingredients like nuts, seeds, lentils, flours, and natural sweeteners such as jaggery or dates, paired with fresh seasonings."
          {...getOverrideProps(
            overrides,
            "Our snacking range has artisan sweets and savory treats made with wholesome ingredients like nuts, seeds, lentils, flours, and natural sweeteners such as jaggery or dates, paired with fresh seasonings."
          )}
        ></Text>
      </Flex>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Container")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="293px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Bottom")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Content52311678")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 501")}
            >
              <Flex
                gap="8px"
                direction="column"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Image52311680")}
              >
                <Image
                  width="200px"
                  height="188.41px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={image}
                  {...getOverrideProps(overrides, "Image52311681")}
                ></Image>
              </Flex>
              <Flex
                gap="72px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="8px 8px 8px 8px"
                children={sliderImage}
                {...getOverrideProps(overrides, "SliderImage")}
              ></Flex>
              <Flex
                gap="20px"
                direction="row"
                width="284px"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311683")}
              >
                <Image
                  width="32px"
                  height="22px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={firstDot}
                  {...getOverrideProps(overrides, "Image52311684")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="225px"
                  height="44px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Nutrient-dense and protein-packed."
                  {...getOverrideProps(
                    overrides,
                    "Nutrient-dense and protein-packed."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="20px"
                direction="row"
                width="284px"
                height="80px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311686")}
              >
                <Image
                  width="32px"
                  height="33px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={secondDot}
                  {...getOverrideProps(overrides, "Image52311687")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="225px"
                  height="44px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="No artificial sweeteners, preservatives, and flavors."
                  {...getOverrideProps(
                    overrides,
                    "No artificial sweeteners, preservatives, and flavors."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="20px"
                direction="row"
                width="284px"
                height="62px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311689")}
              >
                <Image
                  width="32px"
                  height="35px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={thirdDot}
                  {...getOverrideProps(overrides, "Image52311690")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="230px"
                  height="44px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Prepared with fresh oil or ghee for superior taste."
                  {...getOverrideProps(
                    overrides,
                    "Prepared with fresh oil or ghee for superior taste."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="20px"
                direction="row"
                width="284px"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311692")}
              >
                <Image
                  width="35px"
                  height="21px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={fourthDot}
                  {...getOverrideProps(overrides, "Image52311693")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="700"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="225px"
                  height="44px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Made in fresh weekly batches or on order!"
                  {...getOverrideProps(
                    overrides,
                    "Made in fresh weekly batches or on order!"
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="29px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Button52311695")}
          >
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Browse Savoury"
              onClick={() => {
                buttonFiveFourOneSevenOneSevenTwoTwoOnClick();
              }}
              {...getOverrideProps(overrides, "Button54171722")}
            ></Button>
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Browse Sweets"
              onClick={() => {
                buttonFiveFourOneSevenOneSevenThreeOneOnClick();
              }}
              {...getOverrideProps(overrides, "Button54171731")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
