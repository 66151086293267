/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CsrPriceOverrideControls from 'csr/components/CsrPriceOverrideControls';
import Price from 'material-kit/components/Price';
import flatMap from 'lodash/flatMap';
import CartOrderItemStyle from './CartOrderItem.module.scss';
import classNames from 'classnames';
import SkuWithQuantityPicker from 'catalog/product/components/Product/SkuWithQuantityPicker';
import { trackEvent } from '../../events/TrackEvent';
import { EVENT_NAME } from '../../events/EventName';

const CartOrderItem = (props) => {
  const [quantity, setQuantity] = useState(props.quantity || 1);
  const [selectedSku, setSelectedSku] = useState(props.defaultSku);

  const _getProductOptionDisplayValues = () => {
    const { product, orderItemAttributes } = props;

    return (
      orderItemAttributes &&
      orderItemAttributes
        .map((attr) => {
          const option = null; // find(product.productOption, { attributeName: `productOption.${attr.name}` })

          return !option
            ? {
                label: attr.name,
                value: attr.value,
              }
            : undefined;
        })
        .filter((val) => !!val)
    );
  };

  const _getAppliedPromotionMessages = () => {
    const { orderItemPriceDetails, totalAdjustmentValue } = props;

    if (!totalAdjustmentValue || totalAdjustmentValue.amount === '0.00') {
      return [];
    }

    return flatMap(orderItemPriceDetails, (priceDetail) => {
      if (!priceDetail.adjustment) {
        return [];
      }

      return priceDetail.adjustment
        .map((adjustment) => adjustment.marketingMessage)
        .filter((a) => a);
    });
  };

  const updateQuantity = (newQuantity) => {
    if (newQuantity < 1) {
    } else {
      setQuantity(newQuantity);
      props
        .updateQuantity(props.id, +newQuantity)
        .then((response) => {
          if (response && response.payload.cart) {
            trackEvent(EVENT_NAME.CART_UPDATED, response.payload.cart);
          }
        })
        .catch((error) => {
          console.error('Error updating quantity:', error);
        });
    }
  };

  const _updateSkus = (newSku) => {
    setSelectedSku(newSku);
    props.updateSku(props.id, newSku.id).then((action) => {
      var tmp = null;
      if (selectedSku) {
        for (var i = 0; i < props.additionalSkus.length; i++) {
          if (props.additionalSkus[i].id == newSku.id)
            tmp = props.additionalSkus[i];
        }
        if (
          tmp &&
          tmp.productQuantityAvailable &&
          tmp.productQuantityAvailable < quantity && tmp.productQuantityAvailable > 0
        ) {
          props.updateQuantity(props.id, +tmp.productQuantityAvailable);
          setQuantity(+tmp.productQuantityAvailable);
        }
      }
      if (action && action.payload.cart) {
        trackEvent(EVENT_NAME.CART_UPDATED, action.payload.cart);
      }
      if (!action.payload.error) {
        //  this.props.resetReduxForm('product')
      }
    });
  };

  const _renderItemDetails = () => {
    const { id, product, additionalSkus, defaultSku } = props;
    const productOptionDisplayValues = _getProductOptionDisplayValues();
    const appliedPromotionMessages = _getAppliedPromotionMessages();
    return (
      <div
        className={classNames(
          'col-lg-12 col-md-12 col-sm-12 col-xs-12',
          CartOrderItemStyle.PaddingZero
        )}
      >
        <div
          className={classNames('row', CartOrderItemStyle.OrderItemDescParent)}
        >
          {false && additionalSkus && additionalSkus.length > 0 && (
            <div className="col-lg-4 col-md-3 col-sm-4 col-xs-12">
              <p className={CartOrderItemStyle.AtrOptionName}>Variation</p>
              <div
                className={classNames(
                  'form-group label-floating is-empty',
                  CartOrderItemStyle.CartOrderItem_ProductOptionDiv
                )}
              >
                <select
                  className={classNames(
                    'form-control',
                    CartOrderItemStyle.CartOrderItem_ProductOptionValue
                  )}
                  label="State"
                  name="addrdadsa"
                  type="select"
                >
                  {additionalSkus &&
                    additionalSkus.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.productOptionsValue}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}

          <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12">
            <SkuWithQuantityPicker
            allOrderItems={props.allOrderItems}
            editMode={true}
              defaultSku={defaultSku}
              additionalSkus={additionalSkus}
              onSkuChange={_updateSkus}
              selectedSku={selectedSku}
              quantity={quantity}
              updateQuantity={updateQuantity}
              productOptionLabel={'Variation'}
              productOptionLabelClass={CartOrderItemStyle.AtrOptionName}
              quantityLabel={'Quantity'}
              quantityLabelClass={CartOrderItemStyle.AtrOptionName}
            ></SkuWithQuantityPicker>
          </div>
          <div
            className={classNames(
              false && additionalSkus && additionalSkus.length > 0
                ? 'col-lg-2 col-md-2 col-sm-2 col-xs-12'
                : 'col-lg-8 col-md-9 col-sm-8 col-xs-12',
              CartOrderItemStyle.CartOrderItem_RemoveDiv
            )}
          >
            <a
              onClick={() =>
                props.removeFromCart(id).then((response) => {
                  if (response && response.payload.cart) {
                    if (response.payload.cart.orderItems == null) {
                      trackEvent(
                        EVENT_NAME.CART_DELETED,
                        response.payload.cart
                      );
                    } else {
                      trackEvent(
                        EVENT_NAME.CART_UPDATED,
                        response.payload.cart
                      );
                    }
                  }
                })
              }
            >
              <img
                className={CartOrderItemStyle.Wishlist_icon}
                src="/static/img/trash.svg"
              />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const {
    id,
    name,
    priceBeforeAdjustments,
    primaryMedia,
    retailPrice,
    salePrice,
    productUrl,
    totalPrice,
  } = props;
  const itemDetails = _renderItemDetails();

  return (
    <div className={classNames('row', CartOrderItemStyle.CartOrderItem)}>
      <div
        className={classNames(
          'col-sm-12 col-md-12 col-lg-12',
          CartOrderItemStyle.PaddingZero
        )}
      >
        <div
          className={classNames(
            'col-lg-2 col-md-2 col-sm-2 col-xs-12',
            CartOrderItemStyle.CartOrderItem__ImageDiv
          )}
        >
          <div
            className={classNames(
              'col-lg-2 col-md-2 col-sm-2 col-xs-12',
              CartOrderItemStyle.CartOrderItem__ImageDiv
            )}
          >
            <div className={CartOrderItemStyle.CartOrderItem__image}>
              <img
                src={
                  primaryMedia ? primaryMedia.url : '/static/img/noPhoto.png'
                }
                alt={primaryMedia ? primaryMedia.altText : 'No photo available'}
              />
            </div>
          </div>
        </div>

        {/* Description */}
        <div
          className={classNames(
            'col-lg-10 col-md-10 col-sm-10 col-xs-12',
            CartOrderItemStyle.CartOrderItem_Desc
          )}
        >
          <div className="row">
            {/* Product Name */}
            <div className="col-lg-9">
              <div
                className={classNames(
                  'text-uppercase',
                  CartOrderItemStyle.CartOrderItem__name
                )}
              >
                {productUrl ? <Link to={`${productUrl}`}>{name}</Link> : name}
              </div>
            </div>

            {/* Product Pricing */}
            <div
              className={classNames(
                'col-lg-3',
                CartOrderItemStyle.CartOrderItem__pricing
              )}
            >
              <div>
                {/* Line Item Total */}
                <Price
                  className={CartOrderItemStyle.CartOrderItem__pricing__bold}
                  retailPrice={totalPrice}
                />
              </div>

              {/* <!-- CSR Price Override --> */}
              <CsrPriceOverrideControls id={id} />
            </div>

            <div
              className={classNames(
                'col-lg-12',
                CartOrderItemStyle.CartOrderItem_salePrice
              )}
            >
              <div>
                <span
                  className={CartOrderItemStyle.CartOrderItem_salePriceText}
                >
                  Single unit price
                </span>
                <span>
                  <Price
                    className={CartOrderItemStyle.CartOrderItem__pricing__bold}
                    salePrice={salePrice}
                    retailPrice={retailPrice}
                    price={priceBeforeAdjustments}
                  />
                </span>
              </div>
            </div>
          </div>

          {/* Item Details */}
          {itemDetails}
        </div>
      </div>
    </div>
  );
};

export default CartOrderItem;
