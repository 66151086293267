/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Image, useBreakpointValue } from "@aws-amplify/ui-react";
import NavLinks from "./NavLinks";
import CartBadge from "./CartBadge";
export default function Header(props) {
  const {
    logoSrc,
    searchIconSrc,
    heartIconSrc,
    worldIconSrc,
    cartIconSrc,
    menuIconSrc,
    worldIconRef,
    searchIconRef,
    onHeartIconClick,
    onSearchIconClick,
    onWorldIconClick,
    onMenuIconClick,
    cartIconRef,
    mobileMenuIconRef,
    onProfileIconClick,
    navLinks,
    onLogoClick,
    accountIconSrc,
    onCartIconClick,
    isCartBadgeVisible,
    cartBadgeAmount,
    isCartIconHidden,
    onCartBadgeClick,
    overrides: overridesProp,
    ...restProp
  } = props;
  const variants = [
    {
      overrides: {
        LogoRdfood2024image: {},
        Logo: {},
        NavLinks: {},
        Image_search: {},
        Image_favorite: {},
        Image_currency_switcher: {},
        Image_account_circle: {},
        Image_local_mall: {},
        Image_menu: {},
        CartBadge: {},
        Icons: {},
        Header: {},
      },
      variantValues: { breakpoint: "base" },
    },
    {
      overrides: {
        LogoRdfood2024image: {},
        Logo: {},
        NavLinks: {},
        Image_search: {},
        Image_favorite: {},
        Image_currency_switcher: {},
        Image_account_circle: {},
        Image_local_mall: {},
        Image_menu: {},
        CartBadge: {},
        Icons: {},
        Header: { width: "599px" },
      },
      variantValues: { breakpoint: "small" },
    },
    {
      overrides: {
        LogoRdfood2024image: {},
        Logo: {},
        NavLinks: {},
        Image_search: {},
        Image_favorite: {},
        Image_currency_switcher: {},
        Image_account_circle: {},
        Image_local_mall: {},
        Image_menu: {},
        CartBadge: { device: "Desktop", padding: "5px 8px 5px 8px" },
        Icons: {},
        Header: { width: "1279px", padding: "16px 36px 16px 36px" },
      },
      variantValues: { breakpoint: "medium" },
    },
    {
      overrides: {
        LogoRdfood2024image: { width: "98.82px", height: "80px" },
        Logo: { width: "92px" },
        NavLinks: { display: "flex" },
        Image_search: {},
        Image_favorite: { display: "block" },
        Image_currency_switcher: { display: "block" },
        Image_account_circle: { display: "block" },
        Image_local_mall: {},
        Image_menu: { display: "none" },
        CartBadge: { device: "Desktop", padding: "5px 8px 5px 8px" },
        Icons: {},
        Header: { width: "1439px", padding: "16px 64px 16px 64px" },
      },
      variantValues: { breakpoint: "large" },
    },
    {
      overrides: {
        LogoRdfood2024image: { width: "98.82px", height: "80px" },
        Logo: { width: "92px" },
        NavLinks: { display: "flex" },
        Image_search: {},
        Image_favorite: { display: "block" },
        Image_currency_switcher: { display: "block" },
        Image_account_circle: { display: "block" },
        Image_local_mall: {},
        Image_menu: { display: "none" },
        CartBadge: { device: "Desktop", padding: "5px 8px 5px 8px" },
        Icons: {},
        Header: { width: "1920px", padding: "16px 64px 16px 64px" },
      },
      variantValues: { breakpoint: "xxl" },
    },
  ];
  const breakpointHook = useBreakpointValue({
    base: "base",
    small: "small",
    medium: "medium",
    large: "large",
    xxl: "xxl",
  });
  const rest = { style: { transition: "all 0.25s" }, ...restProp };
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, {
      breakpoint: breakpointHook,
      ...props,
    }),
    overridesProp || {}
  );
  return (
    <Flex
      gap="109px"
      direction="row"
      width="320px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(253,252,247,1)"
      display="flex"
      {...getOverrideProps(overrides, "Header")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="column"
        width="51px"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        onClick={onLogoClick}
        {...getOverrideProps(overrides, "Logo")}
      >
        <Image
          width="unset"
          height="45.33px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={logoSrc}
          {...getOverrideProps(overrides, "LogoRdfood2024image")}
        ></Image>
      </Flex>
      <NavLinks
        display="none"
        gap="32px"
        direction="row"
        width="585px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(253,252,247,1)"
        navLinks={navLinks}
        {...getOverrideProps(overrides, "NavLinks")}
      ></NavLinks>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "Icons")}
      >
        <Image
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={searchIconSrc}
          ref={searchIconRef}
          onClick={onSearchIconClick}
          {...getOverrideProps(overrides, "Image_search")}
        ></Image>
        <Image
          width="24px"
          height="24px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={heartIconSrc}
          onClick={onHeartIconClick}
          {...getOverrideProps(overrides, "Image_favorite")}
        ></Image>
        <Image
          width="24px"
          height="24px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={worldIconSrc}
          ref={worldIconRef}
          onClick={onWorldIconClick}
          {...getOverrideProps(overrides, "Image_currency_switcher")}
        ></Image>
        <Image
          width="24px"
          height="24px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={accountIconSrc}
          onClick={onProfileIconClick}
          {...getOverrideProps(overrides, "Image_account_circle")}
        ></Image>
        <Image
          width="24px"
          height="24px"
          display={isCartIconHidden == true ? "none" : "block"}
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={cartIconSrc}
          ref={cartIconRef}
          onClick={onCartIconClick}
          {...getOverrideProps(overrides, "Image_local_mall")}
        ></Image>
        <Image
          width="24px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={menuIconSrc}
          ref={mobileMenuIconRef}
          onClick={onMenuIconClick}
          {...getOverrideProps(overrides, "Image_menu")}
        ></Image>
        <CartBadge
          display={isCartBadgeVisible == true ? "block" : "none"}
          gap="10px"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          borderRadius="32px"
          backgroundColor="rgba(191,64,64,1)"
          device="Mobile"
          amount={cartBadgeAmount}
          onClick={onCartBadgeClick}
          {...getOverrideProps(overrides, "CartBadge")}
        ></CartBadge>
      </Flex>
    </Flex>
  );
}
