/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text } from "@aws-amplify/ui-react";
export default function QuickNavigation(props) {
  const {
    profileImgSrc,
    helpImgSrc,
    shopImgSrc,
    onProfileIconClick,
    onHelpIconClick,
    onShopIconClick,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="40px"
      direction="row"
      width="320px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "QuickNavigation")}
      {...rest}
    >
      <Flex
        gap="4px"
        direction="column"
        width="42px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={onShopIconClick}
        {...getOverrideProps(overrides, "Button48131905")}
      >
        <Image
          width="17px"
          height="16px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={shopImgSrc}
          {...getOverrideProps(overrides, "Image48131864")}
        ></Image>
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="400"
          color="rgba(106,51,22,1)"
          lineHeight="18px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Shop"
          {...getOverrideProps(overrides, "label48131907")}
        ></Text>
      </Flex>
      <Flex
        gap="4px"
        direction="column"
        width="41px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={onHelpIconClick}
        {...getOverrideProps(overrides, "Button48131908")}
      >
        <Image
          width="16px"
          height="16px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={helpImgSrc}
          {...getOverrideProps(overrides, "Image48131863")}
        ></Image>
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="400"
          color="rgba(106,51,22,1)"
          lineHeight="18px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Help"
          {...getOverrideProps(overrides, "label48131910")}
        ></Text>
      </Flex>
      <Flex
        gap="4px"
        direction="column"
        width="42px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={onProfileIconClick}
        {...getOverrideProps(overrides, "Button48131911")}
      >
        <Image
          width="17px"
          height="16px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={profileImgSrc}
          {...getOverrideProps(overrides, "Image48131862")}
        ></Image>
        <Text
          fontFamily="Inter"
          fontSize="12px"
          fontWeight="400"
          color="rgba(106,51,22,1)"
          lineHeight="18px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="37px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Profile"
          {...getOverrideProps(overrides, "label48131913")}
        ></Text>
      </Flex>
    </Flex>
  );
}
