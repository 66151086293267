/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Divider, Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function Announcementbar(props) {
  const {
    lines,
    announcmentMsg2,
    announcmentMsg1,
    onPrevBtnClick,
    onNextBtnClick,
    overrides: overridesProp,
    ...rest
  } = props;
  const variants = [
    {
      overrides: {
        "Bounding box56944627": {},
        chevron_backward56944628: {},
        chevron_backward56944626: {},
        Button56473231: {},
        text42472980: {},
        Divider: {},
        text42472982: {},
        Message: {},
        "Bounding box56944621": {},
        chevron_forward56944622: {},
        chevron_forward56944620: {},
        Button56473232: {},
        Announcementbar: {},
      },
      variantValues: { lines: "Twolines" },
    },
    {
      overrides: {
        "Bounding box56944627": {},
        chevron_backward56944628: {},
        chevron_backward56944626: {},
        Button56473231: {},
        text42472980: {
          width: "492px",
          children: "Free Delivery On Orders Above \u20AC60.",
        },
        Divider: { height: "20px", display: "none" },
        text42472982: { color: "rgba(21,71,38,1)", display: "none" },
        Message: { width: "225px", shrink: "0" },
        "Bounding box56944621": {},
        chevron_forward56944622: {},
        chevron_forward56944620: { width: "24px", shrink: "0" },
        Button56473232: {},
        Announcementbar: { width: "406px" },
      },
      variantValues: { lines: "oneline" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="64px"
      direction="row"
      width="576px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      padding="0px 24px 0px 24px"
      backgroundColor="rgba(0,51,17,1)"
      display="flex"
      onPreviousBtnClick=""
      {...getOverrideProps(overrides, "Announcementbar")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="row"
        width="24px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(255,255,255,1)"
        borderRadius="2px"
        padding="0px 0px 0px 0px"
        display="flex"
        onClick={onPrevBtnClick}
        {...getOverrideProps(overrides, "Button56473231")}
      >
        <View
          width="unset"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "chevron_backward56944626")}
        >
          <View
            width="24px"
            height="24px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(217,217,217,1)"
            {...getOverrideProps(overrides, "Bounding box56944627")}
          ></View>
          <Icon
            width="6.4px"
            height="10.38px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 6.400000095367432,
              height: 10.378377914428711,
            }}
            paths={[
              {
                d: "M5.18919 10.3784L0 5.18919L5.18919 0L6.4 1.21081L2.42162 5.18919L6.4 9.16757L5.18919 10.3784Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="25%"
            bottom="31.76%"
            left="33.33%"
            right="40%"
            {...getOverrideProps(overrides, "chevron_backward56944628")}
          ></Icon>
        </View>
      </Flex>
      <Flex
        gap="4px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(0,51,17,1)"
        display="flex"
        {...getOverrideProps(overrides, "Message")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="500"
          color="rgba(255,255,255,1)"
          lineHeight="18px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="181px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Made in India, Made to Order"
          {...getOverrideProps(overrides, "text42472980")}
        ></Text>
        <Divider
          height="16px"
          shrink="0"
          size="small"
          orientation="vertical"
          {...getOverrideProps(overrides, "Divider")}
        ></Divider>
        <Text
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="500"
          color="rgba(255,255,255,1)"
          lineHeight="18px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="222px"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={announcmentMsg2}
          {...getOverrideProps(overrides, "text42472982")}
        ></Text>
      </Flex>
      <Flex
        gap="8px"
        direction="row"
        width="24px"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(255,255,255,1)"
        borderRadius="2px"
        padding="0px 0px 0px 0px"
        display="flex"
        onClick={onNextBtnClick}
        {...getOverrideProps(overrides, "Button56473232")}
      >
        <View
          width="unset"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "chevron_forward56944620")}
        >
          <View
            width="24px"
            height="24px"
            display="none"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            backgroundColor="rgba(217,217,217,1)"
            {...getOverrideProps(overrides, "Bounding box56944621")}
          ></View>
          <Icon
            width="6.4px"
            height="10.38px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 6.400000095367432,
              height: 10.378377914428711,
            }}
            paths={[
              {
                d: "M3.97838 5.18919L0 1.21081L1.21081 0L6.4 5.18919L1.21081 10.3784L0 9.16757L3.97838 5.18919Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="25%"
            bottom="31.76%"
            left="33.33%"
            right="40%"
            {...getOverrideProps(overrides, "chevron_forward56944622")}
          ></Icon>
        </View>
      </Flex>
    </Flex>
  );
}
