/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { Component, useRef, useState, useEffect } from 'react';
import { Link, Redirect, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from 'material-kit/components/Button';
import ShippingInfoForm, {
  ReadOnlyShippingInfoForm,
} from 'checkout/components/ShippingInfoForm';
import PaymentMethods, {
  ReadOnlyPaymentMethods,
} from 'checkout/components/PaymentMethods';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import CheckoutStagesScss from './CheckoutStages.module.scss';
import GlobalScss from '../../../layout/style/common.scss';
import SamplePaymentService from '../../service/SamplePaymentService';
import RevolutCheckout from '@revolut/checkout';
import request from 'superagent';
import Checkbox from 'material-kit/components/Checkbox';
import TermsAndConditionsModal from '../../../layout/components/TermsAndConditions/TermsAndConditionsModal';
import {
  trackAddPaymentInfo,
  trackAddShippingInfo,
} from '../../../analytics/events';
import debounce from 'lodash.debounce';
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
import classNames from 'classnames';

class CheckoutStages extends Component {
  
  state = {
    modalIsOpen: false,
    termsChecked: false,
  };

  isTermsChecked = () => {
    return this.state.termsChecked;
  };

  _handleClick = (e) => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  _onClose = () => {
    this.setState({ modalIsOpen: false });
  };

  static pageState = {
    revolutPayButtonMounted: 0,
  };

  constructor(props, total) {
    super(props);
    CheckoutStages.pageState.revolutPayButtonMounted = 0;
  }

  render() {
    if (typeof window !== 'undefined') {
      import('react-toastify/dist/ReactToastify.css');
    }
    return (
      <div className={CheckoutStagesScss.CheckoutStages}>
        <Route
          path="/checkout"
          exact
          render={({ match }) => match && <Redirect to="/checkout/shipping" />}
        />
        <Route
          path="/checkout/shipping"
          exact
          children={(props) => (
            <CheckoutStages.Shipping
              {...props}
              isTermsChecked={this.isTermsChecked}
              /*termsChecked={this.state.termsChecked}*/ isModalOpen={
                this.state.modalIsOpen
              }
              handleClick={this._handleClick}
              onClose={this._onClose}
              onPerformCheckout={this.props.onPerformCheckout}
              fetchCart={this.props.fetchCart}
              fulfillmentGroup={this.props.fulfillmentGroup}
              total={this.props.total}
              props={this.props}
              authenticationToken={this.props.authenticationToken}
              customerToken={this.props.customerToken}
            />
          )}
        />
        <Route
          path="/checkout/shipping/success"
          exact
          children={(props) => (
            <CheckoutSuccess
              {...props}
              onPerformCheckout={this.props.onPerformCheckout}
            />
          )}
        />
        <Route
          path="/checkout/shipping/failure"
          exact
          children={(props) => (
            <CheckoutFailure
              {...props}
              onPerformCheckout={this.props.onPerformCheckout}
            />
          )}
        />
        <Route
          path="/checkout/payment"
          exact
          render={({ match }) => match && <Redirect to="/checkout/shipping" />}
        />
      </div>
    );
  }
}

class CheckoutSuccess extends React.Component {
  componentDidMount() {
    const { match, onPerformCheckout } = this.props;
    const isActive = !!match;
    if (isActive) {
      onPerformCheckout(SamplePaymentService.Type.RevolutPay);
    }
  }

  render() {
    return null;
  }
}

class CheckoutFailure extends React.Component {
  componentDidMount() {
    const { match } = this.props;
    const isActive = !!match;
    if (isActive) {
      alert('There was an error in processing your order!');
    }
  }

  render() {
    return null;
  }
}

CheckoutStages.Shipping = ({
  isModalOpen,
  handleClick,
  onClose,
  onPerformCheckout,
  fetchCart,
  fulfillmentGroup,
  total,
  props,
  authenticationToken,
  customerToken,
  history,
  location,
  match,
  isTermsChecked,
}) => {
  
  const [termsChecked, setTermsChecked] = useState(true);
  const [revolutConfig, setRevolutConfig] = useState(null);
  const [revolutAmountCheck, setRevolutAmountCheck] = useState(0);

  const termsCheckedRef = useRef(termsChecked);
  const revolutPayRef = useRef(null);

  useEffect(() => {
    termsCheckedRef.current = termsChecked;
  }, [termsChecked]);
  useEffect(() => {
    // Fetch Revolut configuration from backend
    request
      .get('/api/cart/revolut/config')
      .set('Content-Type', 'application/json')
      .set('Authorization', authenticationToken || null)
      .set('X-Customer-Token', customerToken || null)
      .then((response) => {
        setRevolutConfig(response.body);
      })
      .catch((error) => {
        console.error('Failed to fetch Revolut config:', error);
      });
  }, [authenticationToken, customerToken]);

  const validate = () => {
    if (!termsCheckedRef.current) {
      throw new Error('Please first accept Terms and Conditions!');
    }
    return true;
  };

  const onAccept = () => {
    setTermsChecked(true);
    const mainDiv = revolutPayRef.current;
    const nestedDiv = mainDiv.querySelector('div');
    const button = nestedDiv.querySelector('button');
    if (button) {
      button.disabled = false;
      button.style.backgroundColor = 'transparent';
      button.style.pointerEvents = 'all';
    }
  };

  const onChange = () => {
    setTermsChecked((prev) => !prev);
    const mainDiv = revolutPayRef.current;
    const nestedDiv = mainDiv.querySelector('div');
    const button = nestedDiv.querySelector('button');
    if (button) {
      if (termsChecked) {
        button.disabled = true;
        button.style.backgroundColor = 'rgba(239, 239, 239, 0.3)';
        button.style.pointerEvents = 'none';
      } else {
        button.disabled = false;
        button.style.backgroundColor = 'transparent';
        button.style.pointerEvents = 'all';
      }
    }
  };

  const isActive = !!match;
  const isPrevious =
    !isActive && ['/checkout/review'].includes(location.pathname);

  if (isActive) CheckoutStages.pageState.revolutPayButtonMounted = 0;

  const shippableFulfillmentGroup = find(
    fulfillmentGroup,
    (fg) =>
      !['DIGITAL', 'GIFT_CARD', 'PHYSICAL_PICKUP'].includes(fg.fulfillmentType)
  );

  // this tries to resolve issue described in the RE-207
  if (
    revolutConfig &&
    typeof window !== 'undefined' &&
    //CheckoutStages.pageState.revolutPayButtonMounted != 1 &&
    revolutPayRef.current &&
    (revolutAmountCheck != total.amount*100 || CheckoutStages.pageState.revolutPayButtonMounted != 1)
  ) {

    const isLater =
      !isActive &&
      ['/checkout/shipping', '/checkout/payment'].includes(location.pathname);
    RevolutCheckout.payments({
      locale: 'en-US', // Optional, defaults to 'en'
      mode: revolutConfig.mode, // Optional, defaults to prod
      publicToken: revolutConfig.apiPublicKey, // Merchant sandbox public API key
    }).then((paymentInstance) => {
      setRevolutAmountCheck(total.amount*100)
      const revolutPay = paymentInstance.revolutPay;
      const paymentOptions = {
        mobileRedirectUrls: {
          success: window.location.origin + '/checkout/shipping/success',
          failure: window.location.origin + '/checkout/shipping/failure',
          cancel: window.location.origin + '/checkout/shipping/',
        },
        validate: validate,
        buttonStyle: {
          cashback: false,
        },
        currency: total.currency, // 3-letter currency code
        totalAmount: total.amount * 100, // In lowest denomination e.g., cents
        createOrder: () => {
          const serverSideCall = () => {
            return new Promise((resolve, reject) => {
              request
                .get('/api/cart/revolut/token')
                .set('Content-Type', 'application/json')
                .set('Authorization', authenticationToken || null)
                .set('X-Customer-Token', customerToken || null)
                .end((error, response) => {
                  if (error) {
                    console.error('error:', error);
                    resolve({
                      token: null
                    });
                  } else {
                    const token = response.body.token;
                    resolve({ token: token });
                  }
                });
            });
          };

          return serverSideCall().then((order) => ({
            publicId: order.token,
          }));
        },
        // You can put other optional parameters here
      };
      //revolutPayRef.current.innerHTML = '';
      revolutPay.mount(revolutPayRef.current, paymentOptions);
      revolutPay.on('payment', (event) => {
        trackAddPaymentInfo(SamplePaymentService.Type.RevolutPay, props);
        switch (event.type) {
          case 'cancel': {
            if (event.dropOffState === 'payment_summary') {
              console.log('please complete your payment');
            }
            break;
          }

          case 'success':
            onPerformCheckout(SamplePaymentService.Type.RevolutPay);
            break;

          case 'error':
            alert(event.error);
            break;
        }
      });
      if(CheckoutStages.pageState.revolutPayButtonMounted > 0) {
        const firstChild = revolutPayRef?.current?.querySelector('div'); // Selektuje prvi div
        if (firstChild) {
          firstChild.remove();
        }
      }
   
      CheckoutStages.pageState.revolutPayButtonMounted = 1;

      const mainDiv = revolutPayRef.current;
      const nestedDiv = mainDiv.querySelector('div');
      const button = nestedDiv.querySelector('button');

      if (button && !termsChecked) {
        button.style.backgroundColor = 'rgba(239, 239, 239, 0.3)';
        button.disabled = true;
        button.style.pointerEvents = 'none';
      }
    });
  }

  //CheckoutStages.pageState.revolutPayButtonMounted = 0;

  return (
    <div className="cart">
      <div className={CheckoutStagesScss.CheckoutStages__title}>
        {isPrevious && shippableFulfillmentGroup && (
          <h4 className={CheckoutStagesScss.ShippingDetailsHeader}>
            {isPrevious}
            Shipping details
          </h4>
        )}
        {isPrevious && shippableFulfillmentGroup && (
          <div className={CheckoutStagesScss.SelectDifferentAddres_div}>
            <Button
              component={Link}
              componentProps={{ to: '/checkout/shipping' }}
              lg
              // primary
              simple
              className={classNames(
                "pull-right",
                CheckoutStagesScss.Edit
              )}
            >
              Select a different address
              <img src="/static/img/secondary_CTA_icon.svg" />
            </Button>
          </div>
        )}
      </div>

      <div className={CheckoutStagesScss.CheckoutStages__stage}>
        {/*RE-207: adding shippableFulfillmentGroup to conditions to not attempt to render it if it's null*/}
        {isPrevious && shippableFulfillmentGroup && (
          <ReadOnlyShippingInfoForm
            address={shippableFulfillmentGroup.address}
          />
        )}
        {/*
        isPrevious && (
          <div styleName="CheckoutStagesScss.CheckoutStages__title">
            <h4 styleName="CheckoutStagesScss.ShippingDetailsHeader CheckoutStagesScss.billingDetailsTitle">
              Billing details
            </h4>
          </div>
        )*/}
        {/*RE-207: adding shippableFulfillmentGroup to conditions to not attempt to render it if it's null*/}
        {/*isPrevious && shippableFulfillmentGroup && (
          <div>
            <ReadOnlyShippingInfoForm
              address={shippableFulfillmentGroup.billingAddress}
            />
          </div>
        )*/}
        {isPrevious && (
          <div className={CheckoutStagesScss.Payment_div}>
            <div>
              <div
                id="revolut-pay"
                ref={revolutPayRef}
                className={CheckoutStagesScss.Revolut_pay_button}
              ></div>
            </div>
            <div className={classNames(
              GlobalScss.Terms_and_conditions,
              CheckoutStagesScss.Terms_and_conditions
            )}>
              {/* RE-483 - We have to always enable it and show user a message that he confirms by paying. 

              <Checkbox checked={termsChecked} onChange={onChange}>
                <small className={CheckoutStagesScss.TermsCheckboxLabel}>
                  {' '}
                  I authorise Reddot Food to debit the incurred money using
                  Revolut payment gateway and confirm that I read and accept the
                  <span
                    onClick={handleClick}
                    className={CheckoutStagesScss.TermsAndConditionsLabel}
                  >
                    Terms and Conditions
                  </span>
                  .
                </small>
              </Checkbox>*/}
              <small>
              By placing your order, you confirm you have read and accepted our <span
                    onClick={handleClick}
                    className={CheckoutStagesScss.TermsAndConditionsLabel}
                  >
                    Terms & Conditions
                  </span> and Privacy Policy.

              </small>
              <TermsAndConditionsModal
                isOpen={isModalOpen}
                onRequestClose={onClose}
                onAccept={onAccept}
              />
            </div>

            <div className={classNames(
              GlobalScss.Text_below_header,
              CheckoutStagesScss.PaymentCardsText
            )}>
              <strong>Note</strong>: Following cards will be accepted through
              Revolut Pay
            </div>
            <div className={CheckoutStagesScss.Payment_icons}>
              <p>
                <img
                  className={CheckoutStagesScss.PaymentSchemeImg}
                  src={'/static/img/Revolut2.svg'}
                />
              </p>
              <p>
                <img
                  className={CheckoutStagesScss.PaymentSchemeImg}
                  src={'/static/img/mastercard2.svg'}
                />
              </p>
              <p>
                <img
                  className={CheckoutStagesScss.PaymentSchemeImg}
                  src={'/static/img/visa2.svg'}
                />
              </p>
              <p>
                <img
                  className={CheckoutStagesScss.PaymentSchemeImg}
                  src={'/static/img/googlePay.jpeg'}
                />
              </p>
              <p>
                <img
                  className={CheckoutStagesScss.PaymentSchemeImg}
                  src={'/static/img/applePay.jpeg'}
                />
              </p>
            </div>
          </div>
        )}

        {isActive && (
          <ShippingInfoForm
            afterSubmit={(props) => {
              toast.dismiss()
              return new Promise((resolve, reject) => {
                request
                  .get('/api/cart/iscartok')
                  .set('Content-Type', 'application/json')
                  .set('Authorization', authenticationToken || null)
                  .set('X-Customer-Token', customerToken || null)
                  .end((error, response) => {
                    if (error) {
                      console.error('error:', error);
                      toast.error('Cart contains products that are not available for chosen shipping country!', {
                        position: "bottom-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        theme: "colored",
                        });
                      reject(error); // Reject on error
                    } else if (response.text === 'true') {
                      resolve(true); // Resolve if the response is 'true'
                    } else {
                      resolve(false); // Resolve false otherwise
                    }
                  });
              })
                .then((isCartOk) => {
                  if (isCartOk) {
                    // If the cart is OK, proceed with the cart fetch and navigation
                    fetchCart(true).then((result) => {
                      trackAddShippingInfo(result.payload.cart);
                    });
                    history.push('/checkout/review');
                  } else {
                    console.warn('Cart validation failed');
                  }
                })
                .catch((error) => {
                  // Handle any errors (network issues, etc.)
                  console.error('Error validating cart:', error);
                });
            }}
          />
        )}
      </div>
      <ToastContainer className={CheckoutStagesScss.ToastContainer}/>
    </div>
  );
};

export default CheckoutStages;
