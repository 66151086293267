/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function Cartoverview(props) {
  const {
    label = "0",
    cartItemsSize = 0,
    cartAmount = 0,
    shoppingCartSrc,
    onCartIconClick,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="8px"
      direction="row"
      width="320px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(168,104,0,1)"
      {...getOverrideProps(overrides, "Cartoverview")}
      {...rest}
    >
      <Flex
        gap="4px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "cartcounter")}
      >
        <Image
          width="12px"
          height="15px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          objectFit="cover"
          src={shoppingCartSrc}
          {...getOverrideProps(overrides, "local_mall 2")}
        ></Image>
        <Text
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="500"
          color="rgba(255,255,255,1)"
          lineHeight="21px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children={`${cartItemsSize}${" Items"}`}
          {...getOverrideProps(overrides, "label50021621")}
        ></Text>
      </Flex>
      <Text
        fontFamily="Montserrat"
        fontSize="14px"
        fontWeight="500"
        color="rgba(255,255,255,1)"
        lineHeight="21px"
        textAlign="center"
        display="block"
        direction="column"
        justifyContent="unset"
        width="71px"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={`${"\u20AC"}${cartAmount}`}
        {...getOverrideProps(overrides, "label50021622")}
      ></Text>
      <Button
        width="unset"
        height="unset"
        border="1px SOLID rgba(255,255,255,1)"
        padding="5px 15px 5px 15px"
        shrink="0"
        size="small"
        isDisabled={false}
        variation="primary"
        children="View Cart"
        onClick={onCartIconClick}
        {...getOverrideProps(overrides, "Button")}
      ></Button>
    </Flex>
  );
}
