import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import AccordionDescription from './AccordionDescription';
import Breadcrumbs from 'catalog/breadcrumbs/components/Breadcrumbs';
import Button from 'material-kit/components/Button';
import RightHandRelatedProductWidget from 'content/components/widgets/RightHandRelatedProductWidget';
import ProductImagePicker from 'catalog/product/components/ProductImagePicker';
import ProductBundle from 'catalog/product/components/ProductBundle';
import PromotionMessages from './PromotionMessages';
import ProductViewStyle from './ProductView.module.scss';
import Toaster from '../../../../layout/components/Toaster/Toaster';
import classNames from 'classnames';
import SkuWithQuantityPicker from './SkuWithQuantityPicker';

const ProductView = ({
  addToCartText,
  disabled,
  handleAddToCartSubmit,

  id,
  minPrice,
  maxPrice,
  initialFormValues,
  longDescription,
  manufacturer,
  match,
  media,
  name,
  primaryMedia,
  productOption,
  promotionMessages,
  retailPrice,
  salePrice,
  seoProperties,
  skuBundleItems,
  additionalSkus,
  url,
  defaultSku,
  allOrderItems
}) => {
  const [selectedSku, setSelectedSku] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const _updateSkus = (newSku) => {
    setSelectedSku(newSku);
    var quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == newSku?.id)?.quantity;
    if(quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined) quantityOfThisSkuInCart = 0;
    if (
      newSku &&
      newSku.productQuantityAvailable &&
      newSku.productQuantityAvailable - quantityOfThisSkuInCart < quantity && newSku.productQuantityAvailable > 0
    ) {
      setQuantity(newSku.productQuantityAvailable - quantityOfThisSkuInCart);
    } else {
      setQuantity(quantity);
    }
  };

  const handleSubmit = () => {
    setShowToaster(true);
    handleAddToCartSubmit({
      sku: selectedSku ? selectedSku.id : defaultSku.id,
      isWishlistAdd: false,
      quantity: quantity,
    });
    setQuantity(1);
  };

  const [showToaster, setShowToaster] = useState(false);

  return (
    <div>
      <div className="container">
        <Helmet titleTemplate="%s | Reddot Food Store">
          {seoProperties.title && <title>{seoProperties.title}</title>}
          {seoProperties.description && (
            <meta name="description" content={seoProperties.description} />
          )}

          {seoProperties.title && (
            <meta property="og:title" content={seoProperties.title} />
          )}
          {seoProperties.description && (
            <meta
              property="og:description"
              content={seoProperties.description}
            />
          )}
          {primaryMedia && (
            <meta property="og:image" content={primaryMedia.url} />
          )}

          <meta name="twitter:card" content="summary_large_image" />
          {seoProperties.title && (
            <meta name="twitter:title" content={seoProperties.title} />
          )}
          {seoProperties.description && (
            <meta
              name="twitter:description"
              content={seoProperties.description}
            />
          )}
          {primaryMedia && (
            <meta name="twitter:image" content={primaryMedia.url} />
          )}

          <link rel="canonical" href={url} />
        </Helmet>

        <Breadcrumbs
          productName={name}
          entityType="PRODUCT"
          entityURI={`/${match.params.category}/${match.params.product}`}
        />

        <div className="product-page" style={{ background: 'none' }}>
          <div className="main-product card-product row">
            <ProductImagePicker
              className="col-sm-6"
              //defaultMediaId={primaryMedia.id}
              media={media}
            />

            <div
              className={classNames(
                'col-sm-6',
                ProductViewStyle.ProductRightDiv
              )}
            >
              {' '}
              {/* Product Details */}
              <h3 className={ProductViewStyle.ProductName}>{name}</h3>
              <SkuWithQuantityPicker
              editMode={false}
                defaultSku={defaultSku}
                additionalSkus={additionalSkus}
                onSkuChange={_updateSkus}
                selectedSku={selectedSku}
                quantity={quantity}
                updateQuantity={setQuantity}
                productOptionLabel={'Select a product variation'}
                productOptionLabelClass={
                  ProductViewStyle.ProductOptions_quantity_text
                }
                quantityLabel={'Select Quantity'}
                quantityLabelClass={
                  ProductViewStyle.ProductOptions_quantity_text
                }
                allOrderItems={allOrderItems}
              ></SkuWithQuantityPicker>
              {skuBundleItems && (
                <ProductBundle
                  className="col-sm-12"
                  skuBundleItems={skuBundleItems}
                />
              )}
              <PromotionMessages promotionMessages={promotionMessages} />
              <div>
                <div className="row">
                  <div className="col-sm-10">
                    <Button
                      className={ProductViewStyle.AddToCartButton}
                      type="button"
                      primary={!disabled}
                      disabled={
                        disabled ||
                        (additionalSkus &&
                          additionalSkus.length > 0 &&
                          !selectedSku)
                      }
                      onClick={handleSubmit}
                    >
                      {addToCartText}
                    </Button>
                  </div>
                </div>
              </div>
              <AccordionDescription longDescription={longDescription} />
            </div>
          </div>
        </div>
      </div>
      <div className={ProductViewStyle.CarouselParentDiv}>
        <RightHandRelatedProductWidget
          restrictedWidth={true}
          categoryKey={match.params.category}
          productKey={match.params.product}
          sc={{
            values: {
              headerText: 'Similar Products',
              relatedProductsMaxNum: 100,
              relatedProductsType: 'FEATURED',
            },
          }}
        />
      </div>
      {showToaster && (
        <Toaster
          message="Product is added to the shopping cart"
          onClose={() => setShowToaster(false)}
        />
      )}
    </div>
  );
};

export default ProductView;
