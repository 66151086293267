import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchPageByUrl } from '../common/actions/pageActions';
import GlobalScss from '../../layout/style/common.scss';
import FaqScss from './Faq.scss';
import AccordionFaqScss from './AccordionFaq.scss';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';


class Faq extends React.Component {
  static propTypes = {
    pageData: PropTypes.object,
    fetchPageByUrl: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { match, fetchPageByUrl } = this.props;
    fetchPageByUrl(match.url);
    // TODO testing purposes only
    this.fetchHtmlContent();
  }

  // TODO testing purposes only
  state = {
    content: '',
  };

  faq_url = 'https://uat-reddotfood-eu-static-pages-bucket.s3.eu-west-1.amazonaws.com/static/faq/html/';
  faq_default_filename = 'faq.html';

  fetchHtmlContent() {

    let faq_filename = 'faq.html';
    const clientCountryCode = Cookies.get('blCountry');
    if (clientCountryCode != null) {
      faq_filename =  'faq_' + clientCountryCode + '.html';
    }
    //First try with country specific faq file from s3
    fetch('https://uat-reddotfood-eu-static-pages-bucket.s3.eu-west-1.amazonaws.com/static/faq/html/' + faq_filename)
      .then((response) => {
        if (!response.ok) {
          // If country specific faq is not found, try default one.
          fetch(this.faq_url + this.faq_default_filename)
            .then((response2) => {
              if (!response2.ok) {
                throw new Error('Network response was not ok');
              }
              return response2.text();
            })
            .then((content) => {
              this.setState({ content });
            })
            .catch((error) => {
              console.error('Error fetching HTML content:', error);
            });
        } else {
          return response.text();
        }
      })
      .then((content) => {
        if (content) {
          this.setState({ content });
        }
      })
      .catch((error) => {
        console.error('Error fetching HTML content:', error);
      });
  }
  

  render() {
    const { content } = this.state;
    return (
      <div className="container">
        <Helmet titleTemplate="%s | Reddot Food Store">
          <title>FAQs</title>
          <meta name="description" content="Frequently asked questions" />
          <link rel="canonical" href="/faq" />
        </Helmet>
        <div className={`${GlobalScss['Page_header']}`}>
          <h1 className={`${GlobalScss['Title']} ${FaqScss['Title_align']}`}>
            Frequently Asked Questions
          </h1>
        </div>
        <div>{ReactHtmlParser(content)}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  pageData: state.page[ownProps.match.url],
});

const mapDispatchToProps = {
  fetchPageByUrl,
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
