import React, { useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import ProductOptions from '../ProductOptions/ProductOptions';
import ProductQuantity from '../ProductQuantity';
import SkuWithQuantityPickerStyle from './SkuWithQuantityPicker.module.scss';

const SkuWithQuantityPicker = ({
  defaultSku,
  additionalSkus,
  selectedSku,
  onSkuChange,
  productOptionLabel,
  isInProductCard,
  productOptionLabelClass,
  quantityLabel,
  quantityLabelClass,
  quantity,
  updateQuantity,
  allOrderItems,
  editMode
}) => {
  return (
    <span>
      {additionalSkus && (
        <div className={SkuWithQuantityPickerStyle.Sku_section}>
          {productOptionLabel && productOptionLabel != '' && (
            <p
              className={productOptionLabelClass ? productOptionLabelClass : ''}
            >
              {productOptionLabel}
            </p>
          )}
          <ProductOptions
          allOrderItems={allOrderItems}
          editMode={editMode}
            additionalSkus={additionalSkus}
            onSkuChange={onSkuChange}
            selectedSku={selectedSku}
            isInProductCard={isInProductCard}
            quantity={quantity}
          />
        </div>
      )}
      <div>
        {quantityLabel && quantityLabel != '' && (
          <p className={quantityLabelClass ? quantityLabelClass : ''}>
            {quantityLabel}
          </p>
        )}
        <ProductQuantity
          centered={isInProductCard}
          quantity={quantity}
          updateQuantity={updateQuantity}
          disabledAdd={isAddToCartDisabled(
            additionalSkus,
            defaultSku,
            selectedSku,
            quantity,
            allOrderItems,
            editMode
          )}
        />
      </div>
    </span>
  );
};

const isAddToCartDisabled = (
  additionalSkus,
  defaultSku,
  selectedSku,
  quantity,
  allOrderItems,
  editMode
) => {
  var tmpSku = defaultSku;
  if (additionalSkus && additionalSkus.length !== 0) tmpSku = selectedSku;

  var quantityOfThisSkuInCart = allOrderItems?.find(item => item.defaultSku.id == tmpSku?.id)?.quantity;
  if(quantityOfThisSkuInCart == null || quantityOfThisSkuInCart == undefined || editMode == true) quantityOfThisSkuInCart = 0;

  return (
    !tmpSku ||
    tmpSku.inventoryType == 'UNAVAILABLE' ||
    (tmpSku.inventoryType == 'CHECK_QUANTITY' &&
      quantity + quantityOfThisSkuInCart >= tmpSku.productQuantityAvailable)
  );
};

export default SkuWithQuantityPicker;
