/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function Pulsescontentmobile(props) {
  const {
    image,
    leftArrow,
    rightArrow,
    firstDot,
    secondDot,
    thirdDot,
    onLeftClick,
    onRightClick,
    sliderImage,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="16px"
      direction="column"
      width="300px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Pulsescontentmobile")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content52311706")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="700"
          color="rgba(21,71,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.85px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Pulses(Coming Soon)"
          {...getOverrideProps(overrides, "Pulses(Coming Soon)")}
        ></Text>
        <Text
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="500"
          color="rgba(21,71,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="1.05px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Bring the true taste of tradition to your table with our unpolished lentils. By retaining natural fiber and nutrients, they offer a healthier, more nourishing option for your meals. The perfect addition to any dish, supporting your family’s well-being."
          {...getOverrideProps(
            overrides,
            "Bring the true taste of tradition to your table with our unpolished lentils. By retaining natural fiber and nutrients, they offer a healthier, more nourishing option for your meals. The perfect addition to any dish, supporting your family\u2019s well-being."
          )}
        ></Text>
      </Flex>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Container")}
      >
        <Flex
          gap="-28px"
          direction="column"
          width="287px"
          height="514px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Bottom")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="298px"
            height="unset"
            justifyContent="center"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Content52311711")}
          >
            <Flex
              gap="16px"
              direction="column"
              width="unset"
              height="513px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 501")}
            >
              <Flex
                gap="8px"
                direction="column"
                width="216px"
                height="unset"
                justifyContent="flex-start"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="8px 8px 8px 8px"
                {...getOverrideProps(overrides, "Image52311713")}
              >
                <Image
                  width="unset"
                  height="188.41px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  alignSelf="stretch"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={image}
                  {...getOverrideProps(overrides, "Image52311714")}
                ></Image>
              </Flex>
              <Flex
                gap="72px"
                direction="row"
                width="unset"
                height="unset"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="8px 8px 8px 8px"
                children={sliderImage}
                {...getOverrideProps(overrides, "SliderImage")}
              ></Flex>
              <Flex
                gap="20px"
                direction="row"
                width="290px"
                height="31px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311716")}
              >
                <Image
                  width="32px"
                  height="22px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={firstDot}
                  {...getOverrideProps(overrides, "Image52311717")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="225px"
                  height="21px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Rich earthy flavor."
                  {...getOverrideProps(overrides, "Rich earthy flavor.")}
                ></Text>
              </Flex>
              <Flex
                gap="20px"
                direction="row"
                width="290px"
                height="55px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311719")}
              >
                <Image
                  width="32px"
                  height="33px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={secondDot}
                  {...getOverrideProps(overrides, "Image52311720")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="225px"
                  height="44px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Untouched by artificial polishing."
                  {...getOverrideProps(
                    overrides,
                    "Untouched by artificial polishing."
                  )}
                ></Text>
              </Flex>
              <Flex
                gap="20px"
                direction="row"
                width="290px"
                height="62px"
                justifyContent="flex-start"
                alignItems="center"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 50052311722")}
              >
                <Image
                  width="32px"
                  height="35px"
                  display="block"
                  gap="unset"
                  alignItems="unset"
                  justifyContent="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  objectFit="cover"
                  src={thirdDot}
                  {...getOverrideProps(overrides, "Image52311723")}
                ></Image>
                <Text
                  fontFamily="Montserrat"
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(21,71,38,1)"
                  lineHeight="24px"
                  textAlign="left"
                  display="block"
                  direction="column"
                  justifyContent="unset"
                  letterSpacing="1.05px"
                  width="230px"
                  height="44px"
                  gap="unset"
                  alignItems="unset"
                  shrink="0"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  whiteSpace="pre-wrap"
                  children="Naturally high in Protein & Fiber."
                  {...getOverrideProps(
                    overrides,
                    "Naturally high in Protein & Fiber."
                  )}
                ></Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            gap="29px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Button52311725")}
          >
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={true}
              variation="primary"
              children="Browse Pulses"
              {...getOverrideProps(overrides, "Button54171735")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
