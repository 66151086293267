/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function ColdpressedOilscontentmobile(props) {
  const {
    image,
    leftArrow,
    rightArrow,
    firstDot,
    secondDot,
    thirdDot,
    onLeftClick,
    onRightClick,
    sliderImage,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="16px"
      direction="column"
      width="288px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "ColdpressedOilscontentmobile")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="700"
          color="rgba(21,71,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.85px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Cold-Pressed Oils (Coming Soon)"
          {...getOverrideProps(overrides, "Cold-Pressed Oils (Coming Soon)")}
        ></Text>
        <Text
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="500"
          color="rgba(21,71,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="1.05px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Our range of cold-pressed oils is extracted using traditional methods to retain maximum nutrients and authentic flavor, ensuring every drop is pure and natural."
          {...getOverrideProps(
            overrides,
            "Our range of cold-pressed oils is extracted using traditional methods to retain maximum nutrients and authentic flavor, ensuring every drop is pure and natural."
          )}
        ></Text>
      </Flex>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="497px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Container")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="293px"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Bottom")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 501")}
          >
            <Flex
              gap="8px"
              direction="column"
              width="unset"
              height="unset"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="8px 8px 8px 8px"
              {...getOverrideProps(overrides, "Image52311762")}
            >
              <Image
                width="200px"
                height="188.41px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={image}
                {...getOverrideProps(overrides, "Image52311763")}
              ></Image>
            </Flex>
          </Flex>
          <Flex
            gap="72px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="8px 8px 8px 8px"
            children={sliderImage}
            {...getOverrideProps(overrides, "SliderImage")}
          ></Flex>
          <Flex
            gap="20px"
            direction="row"
            width="288px"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 50052311765")}
          >
            <Image
              width="32px"
              height="22px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={firstDot}
              {...getOverrideProps(overrides, "Image52311766")}
            ></Image>
            <Text
              fontFamily="Montserrat"
              fontSize="14px"
              fontWeight="500"
              color="rgba(21,71,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="1.05px"
              width="206px"
              height="21px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Nutrient-rich & pure."
              {...getOverrideProps(overrides, "Nutrient-rich & pure.")}
            ></Text>
          </Flex>
          <Flex
            gap="20px"
            direction="row"
            width="288px"
            height="33px"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 50052311768")}
          >
            <Image
              width="32px"
              height="33px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={secondDot}
              {...getOverrideProps(overrides, "Image52311769")}
            ></Image>
            <Text
              fontFamily="Montserrat"
              fontSize="14px"
              fontWeight="500"
              color="rgba(21,71,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="1.05px"
              width="225px"
              height="19px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Packed with antioxidants."
              {...getOverrideProps(overrides, "Packed with antioxidants.")}
            ></Text>
          </Flex>
          <Flex
            gap="20px"
            direction="row"
            width="288px"
            height="38px"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 50052311771")}
          >
            <Image
              width="32px"
              height="35px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              objectFit="cover"
              src={thirdDot}
              {...getOverrideProps(overrides, "Image52311772")}
            ></Image>
            <Text
              fontFamily="Montserrat"
              fontSize="14px"
              fontWeight="500"
              color="rgba(21,71,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              letterSpacing="1.05px"
              width="230px"
              height="21px"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Chemical-free & unrefined."
              {...getOverrideProps(overrides, "Chemical-free & unrefined.")}
            ></Text>
          </Flex>
          <Flex
            gap="29px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Button52311774")}
          >
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={true}
              variation="primary"
              children="Browse Cold-Pressed Oils"
              {...getOverrideProps(overrides, "Button54181596")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
