/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function Freeshippingindicatormini(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Message: {}, Freeshippingindicatormini: {} },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        Message: { children: "Add  \u20AC60 more to get FREE Shipping" },
        Freeshippingindicatormini: {},
      },
      variantValues: { state: "ongoing" },
    },
    {
      overrides: {
        Message: { children: "You Qualify for FREE Shipping!" },
        Freeshippingindicatormini: {},
      },
      variantValues: { state: "success" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="row"
      width="320px"
      height="46px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      border="1.3px SOLID rgba(163,123,102,1)"
      borderRadius="8px 8px 0px 0px"
      padding="14.700000047683716px 62.700000047683716px 14.700000047683716px 62.700000047683716px"
      backgroundColor="rgba(253,252,247,1)"
      display="flex"
      {...getOverrideProps(overrides, "Freeshippingindicatormini")}
      {...rest}
    >
      <Text
        fontFamily="Montserrat"
        fontSize="14px"
        fontWeight="500"
        color="rgba(63,125,74,1)"
        lineHeight="21px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="FREE Shipping On Orders Above €60"
        {...getOverrideProps(overrides, "Message")}
      ></Text>
    </Flex>
  );
}
