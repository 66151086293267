import React from 'react';
import BannerWidgetStyle from './BannerWidget.module.scss';
import Button from 'material-kit/components/Button';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import GlobalScss from '../../../../layout/style/common.scss';
import AmplifyTemporalButton from './AmplifyTemporalButton';

const BannerWidget = ({ }) => {
  const lastViewed =
    (typeof window !== 'undefined' && localStorage.getItem('lastViewed')) ||
    '/';

  return (
    <div className={BannerWidgetStyle.BannerWrapper}>
      <div className={GlobalScss.GridWrapper}>
        <div className={BannerWidgetStyle.BannerContentParentDiv}>
          <div>
            <div className={BannerWidgetStyle.BanerHeaderTitleText}>
              Carefully Sourced For Exceptional Flavour
            </div>
            <div className={BannerWidgetStyle.BannerContentText}>
              Why Settle For Junk? Try Our Freshly Made!
            </div>
            <div className={BannerWidgetStyle.BannerButtonContainer}>
              <AmplifyTemporalButton
                to={'/shop' }
              >
                Shop all
              </AmplifyTemporalButton>
              <Button
                className={BannerWidgetStyle.BulkOrdersButtonTextPosition}
                component={Link}
                componentProps={{
                  to: '/bulk-orders', // + lastViewed,
                }}
                lg
                simple
              >
                Bulk Orders
                <img src="/static/img/secondary_CTA_icon.svg" />
              </Button>
            </div>
          </div>
          <div className={BannerWidgetStyle.BicycleImgWrapper}>
            <img
              src="/static/img/home/BANNER_ASSETS/BYCYCLE.svg"
              className={BannerWidgetStyle.BicycleImg}
            />
            <img
              src="/static/img/home/BANNER_ASSETS/CLOUDS.svg"
              className={BannerWidgetStyle.CloudsImg}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerWidget;
