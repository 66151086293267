/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { useEffect, useRef, useState } from 'react';
import MobileCartListItem from '../../../ui-components/MobileCartListItem';
import Minicart from '../../../ui-components/Minicart';
import MiniCartListItem from '../../../ui-components/MiniCartListItem';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { format } from 'layout/util/moneyUtils';
import { trackCartToShop } from '../../../analytics/events';
import FullPageLayoutStyle from 'layout/components/FullPageLayout/FullPageLayout.module.scss';
import Notepopup from '../../../ui-components/Notepopup';
import { updateNote } from '../../../cart/actions/cartActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const minicartOverrides = {
  Minicart: {
    height: '100%',
    '@media (max-width: 648px)': {
      background: 'red',
      width: '100%',
    },
    style: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      overflowY: 'auto',
      overflowX: 'hidden',
      '@media (max-width: 648px)': {
        background: 'blue',
        width: '100%',
      },
    },
  },
  label47621628: {
    style: {
      cursor: 'pointer',
    },
  },
  Button47941944: {
    style: {
      cursor: 'pointer',
    },
  },
  slot: {
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    style: {
      flexDirection: 'column',
    },
  },
};

export const MiniCartPopup = ({
  cart,
  removeFromCart,
  updateQuantity,
  history,
  fetchCart,
  updateNote,
  isCartVisible,
  setIsCartVisible,
}) => {
  const notePopupRef = useRef(null);
  const notePopupOverlayRef = useRef(null);
  const [isNoteVisible, setIsNoteVisible] = useState(false);
  const [notePopUpText, setNotePopUpText] = useState(cart.customerNote);

  useEffect(() => {
    fetchCart();
  }, [fetchCart]);

  const cartRef = useRef(null);

  const handleTextAreaChange = (event) => {
    setNotePopUpText(event.target.value);
  };

  const handleSaveButtonClicked = () => {
    updateNote(notePopUpText)
      .then((response) => {
        if (notePopupRef.current) {
          setIsNoteVisible(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickOutsideCart = (event) => {
    if (
      cartRef.current &&
      !cartRef.current.contains(event.target) &&
      ((notePopupRef.current && !notePopupRef.current.contains(event.target)) ||
        !notePopupRef.current)
    ) {
      setIsCartVisible(false);
    }
  };

  const handleClickOutsideNotePopup = (event) => {
    if (notePopupRef.current && !notePopupRef.current.contains(event.target)) {
      setIsNoteVisible(false);
    }
  };

  useEffect(() => {
    if (isCartVisible) {
      document.addEventListener('mousedown', handleClickOutsideCart);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    };
  }, [isCartVisible]);

  useEffect(() => {
    if (isNoteVisible) {
      document.addEventListener('mousedown', handleClickOutsideNotePopup);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideNotePopup);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideNotePopup);
    };
  }, [isNoteVisible]);

  useEffect(() => {
    if (isCartVisible) {
      document.addEventListener('mousedown', handleClickOutsideCart);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    };
  }, [isCartVisible]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div ref={cartRef} className={FullPageLayoutStyle.cartContainer}>
      <Minicart
        onClose={() => setIsCartVisible(false)}
        onNoteClick={() => setIsNoteVisible(true)}
        onCheckoutClick={() => {
          setIsCartVisible(false);
          history.push('/checkout/shipping');
        }}
        onContinueShoppingClick={() => {
          setIsCartVisible(false);
          trackCartToShop();
        }}
        className={FullPageLayoutStyle.minicart}
        overrides={{
          ...minicartOverrides,
          Freeshippingindicator: {
            status:
              Math.min(100, ((cart?.subTotal?.amount / 60) * 100).toFixed(2)) >=
              100
                ? 'Success'
                : 'Default',
            overrides: {
              Message: {
                style: {
                  flexWrap: window.innerWidth < 648 ? 'wrap' : 'auto',
                },
              },
              Freeshippingindicator: {
                border: 'none',
                borderWidth: 0,
                borderLeftWidth: 0,
                borderLeft: '1px solid transparent',
                borderRight: '1px solid transparent',
                borderTop: '1px solid transparent',
                style: {
                  borderWidth: 0,
                  borderLeftWidth: 0,
                  border: 'none',
                  borderLeft: '1px solid transparent',
                  borderRight: '1px solid transparent',
                  borderTop: '1px solid transparent',
                },
              },
              empty: {
                width: '100%',
              },
              filled: {
                width: cart?.subTotal?.amount
                  ? `${Math.min(
                      100,
                      ((cart?.subTotal?.amount / 60) * 100).toFixed(2)
                    )}%`
                  : '0%',
              },
              icon: {
                style: {
                  transform: 'rotateY(180deg)',
                },
              },
            },
          },
          Button47941944: {
            position: 'absolute',
            style: {
              top: 0,
              right: 0,
              cursor: 'pointer',
            },
          },
          Note: {
            overrides: {
              label: {
                width: '100%',
              },
            },
          },
        }}
        yourCartLabel={`Your Cart (${cart?.itemCount ?? 0})`}
        availFreeShippingLabel={`€ ${cart?.subTotal?.amount ? parseFloat(60 - cart?.subTotal?.amount).toFixed(2) : 60}`}
        slot={
          <>
            {map(
              filter(cart.orderItems, (o) => !o.parentOrderItemId),
              (orderItem) =>
                windowWidth > 650 ? (
                  <MiniCartListItem
                    key={orderItem.id}
                    {...orderItem}
                    onDeleteClick={() => removeFromCart(orderItem.id)}
                    title={orderItem.name}
                    priceLabel={format(
                      orderItem.totalPrice.amount,
                      orderItem.totalPrice.currency
                    )}
                    trashCanIconSrc={'/static/img/TrashCan.svg'}
                    amount={orderItem.quantity}
                    image={orderItem.primaryMedia?.url}
                    productUrl={orderItem.productUrl}
                    pricePer={
                      orderItem.defaultSku?.productOptionsValue
                        ? `${orderItem.defaultSku?.retailPrice.amount} ${orderItem.defaultSku?.retailPrice.currency}/${orderItem.defaultSku?.productOptionsValue}`
                        : ''
                    }
                    savedLabel={
                      orderItem.retailPrice.amount -
                        orderItem.salePrice.amount >
                      0
                        ? `(Saved ${format(
                            (orderItem.retailPrice.amount -
                              orderItem.salePrice.amount) *
                              orderItem.quantity,
                            orderItem.totalPrice.currency
                          )}) `
                        : undefined
                    }
                    overrides={{
                      leftcontent: {
                        maxWidth: '87%',
                      },
                      MiniCartListItem: {
                        width: '100%',
                        border: 0,
                        style: {
                          borderTop: 'none',
                          borderLeft: 'none',
                          borderRight: 'none',
                          borderBottom: '1px solid rgb(137, 148, 159)',
                        },
                      },
                      Button: {
                        style: {
                          cursor: 'pointer',
                        },
                      },
                      label: {
                        style: {
                          cursor: 'pointer',
                        },
                      },
                      'Frame 482': {
                        width: 'auto',
                      },
                      'Frame 484': {
                        width: 'auto',
                      },
                    }}
                    isSale={
                      orderItem.salePrice &&
                      orderItem.salePrice.amount < orderItem.retailPrice.amount
                    }
                    onQuantityDecrease={() => {
                      updateQuantity(orderItem.id, orderItem.quantity - 1);
                    }}
                    onQuantityIncrease={() => {
                      updateQuantity(orderItem.id, orderItem.quantity + 1);
                    }}
                  />
                ) : (
                  <MobileCartListItem
                    key={orderItem.id}
                    {...orderItem}
                    onDeleteClick={() => removeFromCart(orderItem.id)}
                    title={orderItem.name}
                    priceLabel={format(
                      orderItem.totalPrice.amount,
                      orderItem.totalPrice.currency
                    )}
                    amount={orderItem.quantity}
                    image={orderItem.primaryMedia?.url}
                    productUrl={orderItem.productUrl}
                    trashCanIconSrc={'/static/img/TrashCan.svg'}
                    pricePer={orderItem.defaultSku?.productOptionsValue}
                    savedLabel={
                      orderItem.retailPrice.amount -
                        orderItem.salePrice.amount >
                      0
                        ? `(Saved ${format(
                            orderItem.retailPrice.amount -
                              orderItem.salePrice.amount,
                            orderItem.totalPrice.currency
                          )}) `
                        : undefined
                    }
                    overrides={{
                      MobileCartListItem: {
                        width: '100%',
                        border: 0,
                        style: {
                          borderTop: 'none',
                          borderLeft: 'none',
                          borderRight: 'none',
                          borderBottom: '1px solid rgb(137, 148, 159)',
                        },
                      },
                      Button: {
                        style: {
                          cursor: 'pointer',
                        },
                      },
                      'Frame 482': {
                        width: 'auto',
                      },
                    }}
                    isSale={
                      orderItem.salePrice &&
                      orderItem.salePrice.amount < orderItem.retailPrice.amount
                    }
                    onQuantityDecrease={() => {
                      updateQuantity(orderItem.id, orderItem.quantity - 1);
                    }}
                    onQuantityIncrease={() => {
                      updateQuantity(orderItem.id, orderItem.quantity + 1);
                    }}
                  />
                )
            )}
          </>
        }
      />
      {isNoteVisible && (
        <>
          <div
            ref={notePopupOverlayRef}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              position: 'fixed',
              top: 0,
              right: 0,
              width: windowWidth < 648 ? '100%' : '540px',
              bottom: 0,
              zIndex: 1001,
            }}
          >
            <div
              ref={notePopupRef}
              style={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                left: windowWidth < 648 ? 0 : 'auto',
                zIndex: 1002,
              }}
            >
              <Notepopup
                onTextAreaChange={handleTextAreaChange}
                onSaveButtonClicked={handleSaveButtonClicked}
                value={notePopUpText}
                overrides={{
                  Notepopup: {
                    width: windowWidth < 648 ? '100%' : '540px',
                    maxWidth: windowWidth < 648 ? '648px' : 'auto',
                  },
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = {
  updateNote,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MiniCartPopup)
);
