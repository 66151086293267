import React from 'react';
import Freeshippingindicatormini from '../../../ui-components/Freeshippingindicatormini';

const FreeshippingindicatorminiWrapper = ({ cartAmount }) => {

    const freeshippingindicatorminiOverrides = {
        Freeshippingindicatormini: {
            width: '100%',
            style: {
              width: '100%',
              maxWidth: '752px',
            },
        }
    }

  const getShippingState = () => {
    if (!cartAmount || cartAmount === 0) {
      return { state: "default"};
    } else if (cartAmount > 0 && cartAmount < 60) {
      const difference = (60 - cartAmount).toFixed(2);
      return { state: "ongoing", message: `Add €${difference} more to get FREE Shipping` };
    } else {
        return { state: "success"};
    }
  };

  const { state, message } = getShippingState() || {};

  if (!state) return null;

  const overrides = {
    ...freeshippingindicatorminiOverrides,
    ...(state === 'ongoing' && {
      Message: {
        children: message,
      },
    }), 
  };

  return (
    <Freeshippingindicatormini
      state={state}
      overrides={overrides}
    />
  );
};

export default FreeshippingindicatorminiWrapper;
