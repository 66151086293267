/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */


import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import PhoneNumberFieldScss from './PhoneNumberField.scss';
import GlobalScss from '../../../layout/style/common.scss';

const PhoneNumberField = ({
  addon,
  autoComplete = 'on',
  children,
  inputGroup = true,
  input,
  label,
  placeholder,
  meta: { active, touched, error },
  type,
}) => {
  const [phone, setPhone] = useState('');
  useEffect(() => {
    // Dinamički import CSS datoteke samo na klijentskoj strani
    import('react-phone-input-2/lib/style.css');
  }, []);

  const formGroup = (
    <div
      styleName="PhoneNumberFieldScss.PhoneNumberField__form-group"
      className={classNames({
        'form-group': true,
        'error-group': true,
        'is-empty': !input.value,
        'is-focused': active,
        'has-error': touched && error,
      })}
    >
      <label className="control-label">{label}</label>
      <PhoneInput
        class="form-control"
        country="ie"
        onlyCountries={[
          'at',
          'be',
          'bg',
          'hr',
          'cy',
          'cz',
          'dk',
          'ee',
          'fi',
          'fr',
          'de',
          'gr',
          'hu',
          'ie',
          'it',
          'lv',
          'lt',
          'lu',
          'mt',
          'nl',
          'pl',
          'pt',
          'ro',
          'sk',
          'si',
          'es',
          'se',
          'rd',
          'ch',
        ]}
        value={input.value}
        placeholder={placeholder}
        countryCodeEditable={false}
        {...input}
      />
      {touched && error && (
        <span
          className="text-danger"
          styleName="GlobalScss.FieldRequired__error"
        >
          {error}
        </span>
      )}
    </div>
  );

  return formGroup;
};

export default PhoneNumberField;