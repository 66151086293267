import React, { useRef } from 'react';
import connectPage from 'page/common/decorators/connectPage';
import { withScroll } from 'core/components/ScrollToTop';
import { SearchCapable } from 'catalog/search/decorator/searchCapable';
import { Helmet } from 'react-helmet';
import ResultList from 'catalog/search/components/ResultList';
// import BannerAdCarouselWidget from 'content/components/widgets/BannerAdCarouselWidget';
// import HomeMiddlePromoWidget from 'content/components/widgets/HomeMiddlePromoWidget';
import queryString from 'query-string';
import classNames from 'classnames';
import HomeStyle from './Home.module.scss';
import NewsletterSubscribe from './NewsletterSubscribe';
import AboutUsWidget from './AboutUsWidget';
import KindOfFood from './KindOfFood';
import BannerWidget from './BannerWidget';
import TestimonialsWidget from './TestimonialsWidget';
import RightHandRelatedProductWidget from 'content/components/widgets/RightHandRelatedProductWidget';
import WhatSetsReddot from './WhatSetsReddot';
// import VideoPlayer from './VideoPlayer';

const Home = ({ isFetching, results, seoProperties }) => {
  const kindOfFoodRef = useRef(null);
  const resultList = <ResultList numberPerRow={3} results={results} />;

  //screenSize = window.innerWidth
  const headerText =
    typeof window !== 'undefined' && window.innerWidth <= 992
      ? 'Best Sellers'
      : 'Quick look Into Best Sellers';

  return (
    <div className={classNames(HomeStyle.Home, 'container-fluid')}>
      <Helmet titleTemplate="%s | Reddot Food | Authentic Indian Snacks & Spices">
        {seoProperties.title ? (
          <title>{seoProperties.title}</title>
        ) : (
          <title>Home</title>
        )}
        {seoProperties.description ? (
          <meta name="description" content={seoProperties.description} />
        ) : (
          <meta
            name="description"
            content="Welcome to Reddot Food! Explore our premium range of authentic Indian snacks and spices. From savory snacks to sweet treats, we offer a wide range of products made withthe finest ingredients. Explore our selection and enjoy traditional flavors delivered fresh toyour door."
          />
        )}
        <meta charset="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="author" content="Reddot Food Limited" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta
          name="keywords"
          content="Indian snacks, authentic spices, healthy snacks, South Indian food, Reddot Food"
        ></meta>
      </Helmet>

      {/*
                <BannerAdCarouselWidget
                    mcs={[]}
                    scs={[{
                        id: 0,
                        values: {
                            imageUrl: '/cms/static/img/banners/shirt-special.jpg',
                            targetUrl: '/merchandise',
                        }
                    }]}
                />*/}

      {/* Middle promo widget
            <div className='container'>
                <HomeMiddlePromoWidget
                    sc={{
                        values: {
                            htmlContent: `
                                <div id="home_feature">
                                    <h2>HOT SAUCE AFICIONADO?</h2> Click to join our Heat Clinic Frequent Care Program. The place to get all the deals on burn treatment.
                                </div>
                            `
                        }
                    }}
                />

                <hr />

                <div className='row'>
                    <h3 className='text-center'>
                        {`The Heat Clinic's Top Selling Sauces`}
                    </h3>
                    {resultList}
                </div>
            </div>
            */}
      <BannerWidget />
      <KindOfFood kindOfFoodRef={kindOfFoodRef} />
      {/* <VideoPlayer /> */}
      <div
        className={classNames(
          HomeStyle.BestSellerProducts,
          'col-xs-12 col-md-12 col-lg-12'
        )}
      >

        <RightHandRelatedProductWidget
          // resultList={results}
          restrictedWidth={false}
          productKey={'bestSellerProducts'}
          sc={{
            values: {
              headerText: headerText,
              relatedProductsMaxNum: 100,
              relatedProductsType: 'FEATURED',
            },
          }}
        />
      </div>
      <WhatSetsReddot />
      <TestimonialsWidget />
      <AboutUsWidget />
      <NewsletterSubscribe kindOfFoodRef={kindOfFoodRef} />
    </div>
  );
};

const getSearchParams = (props) => {
  const { q, page, sort, ...filters } = queryString.parse(
    props.location.search
  );
  return { q, page, sort, category: '', ...filters };
};

export default withScroll(
  connectPage(SearchCapable('/api/catalog/search', getSearchParams)(Home))
);
