import React, { useState, useEffect, useRef } from 'react';
import './TopOptionalInfoWidget.module.scss';
import TopOptionalInfoWidgetStyle from './TopOptionalInfoWidget.module.scss';
import Button from 'material-kit/components/Button';
import request from 'core/util/superagent'; // Import superagent
import { Announcementbar } from '../../../../../app/ui-components';
import Anouncementbarfinal from '../../../../../app/ui-components/Anouncementbarfinal';
import Cookies from 'js-cookie';

const TopOptionalInfoWidget = () => {
  const [banners, setBanners] = useState([]); // State to store fetched banners
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [timeLeft, setTimeLeft] = useState(3000); // Track the time left for the next slide
  const intervalRef = useRef(null);
  const startTimeRef = useRef(null); // Store when the interval started

  const fetchBanners = async () => {
    try {
      const response = await request.get('/api/optional-banner-info')
      .set('X-Country', Cookies.get('blCountry'));
      if (response.body && response.body.optionalInfoBanners) {
        setBanners(
          response.body.optionalInfoBanners.map((banner) => banner.info)
        );
      }
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  const startRotation = (delay) => {
    if (banners.length > 0) {
      clearInterval(intervalRef.current); // Clear any existing interval
      startTimeRef.current = Date.now(); // Track when the interval starts

      intervalRef.current = setInterval(() => {
        //handleNextClick();
        setFade(false);
        setTimeout(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
          setFade(true);
        }, 100); 
        setTimeLeft(3000);
      }, delay);
    }
  };

  const stopRotation = () => {
    clearInterval(intervalRef.current); // Stop the interval
    const elapsedTime = Date.now() - startTimeRef.current; // Calculate elapsed time
    setTimeLeft((prevTimeLeft) => prevTimeLeft - elapsedTime); // Adjust time left
  };

  const handleNextClick = () => {
    setFade(false); // Start fade-out animation
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
      setFade(true); // Start fade-in animation after content changes
      stopRotation();
    }, 100); // Duration should match the transition duration
  };

  const handlePreviousClick = () => {
    setFade(false); 
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + banners.length) % banners.length
      );
      setFade(true);
      stopRotation();
    }, 100);
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  useEffect(() => {
    if (banners.length > 1) {
      startRotation(timeLeft);
    }
    return () => clearInterval(intervalRef.current);
  }, [banners, currentIndex]);

  let msg1 = banners[currentIndex];
  let msg2 = '';
  let state = (msg1 != null && msg1.includes('|')) ? 'Twolines' : 'oneline';
  if (state == 'Twolines') {
    let [message1, message2] = banners[currentIndex].split('|');
    msg1 = message1;
    msg2 = message2;
  }

  const announcmentOverrides = {
    Anouncementbarfinal: {
      width: '100%',
      onMouseEnter: stopRotation,
      onMouseLeave: () => startRotation(timeLeft),
    },
    Announcementbar: {
      gap: '0px',
      maxWidth: '100%',
      width: '600px',
      alignSelf: 'auto',
      justifyContent: 'center',
      overrides: {
        Button56473232: {
          style: {
            cursor: 'pointer',
          }
        },
        Button56473231: {
          style: {
            cursor: 'pointer',
            zIndex: '2'
          }
        },
        text42472980: {
          children: msg1,
          maxWidth: '100%'
        },
        Message: {
          style: {
            flexWrap: 'wrap',
            transform: fade ? 'translateY(0)' : 'translateY(100%)',
            transition: 'transform 0.3s ease-in-out',
          },
        },
      },
    },
  };

  return (
    <Anouncementbarfinal
      overrides={announcmentOverrides}
      lines={state}
      announcmentMsg2={msg2}
      onPrevBtnClick={handlePreviousClick}
      onNextBtnClick={handleNextClick}
    />
  );

  /*return (
  <section className={TopOptionalInfoWidgetStyle.info_banner}>
    <div>
      {banners.length > 1 && (
        <Button
          onClick={handlePreviousClick}
          className={TopOptionalInfoWidgetStyle.slide_buttons}
          lg
          simple
        >
          <img src="/static/img/left_arrow.svg" />
        </Button>
      )}
      <div
        className={`${TopOptionalInfoWidgetStyle.info_banner_text} ${fade ? TopOptionalInfoWidgetStyle.fade_in : TopOptionalInfoWidgetStyle.fade_out}`}
        onMouseEnter={stopRotation} // Stop the rotation and track time left
        onMouseLeave={() => startRotation(timeLeft)} // Resume with the remaining time
      >
        {banners[currentIndex]}
      </div>
      {banners.length > 1 && (
        <Button
          onClick={handleNextClick}
          className={TopOptionalInfoWidgetStyle.slide_buttons}
          lg
          simple
        >
          <img src="/static/img/right_arrow.svg" />
        </Button>
      )}
    </div>
  </section>
);*/
};

export default TopOptionalInfoWidget;
