/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { useEffect, useCallback, useRef, useState } from 'react';
import MobileMenuItem from '../../../ui-components/MobileMenuitem';
import Mobilemenu from '../../../ui-components/Mobilemenu';
import Submenu from '../../../ui-components/Submenu';
import Primarymenu from '../../../ui-components/Primarymenu';
import Secondarymenu from '../../../ui-components/Secondarymenu';
import Tertiarymenu from '../../../ui-components/Tertiarymenu';
import { CSSTransition } from 'react-transition-group';
import Cookies from 'js-cookie';
import axios from 'axios';
import request from 'core/util/superagent';
import ReactCountryFlag from 'react-country-flag';
import debounce from 'lodash/debounce';

export const HeaderMenu = ({
  activeCountry,
  setActiveCountry,
  history,
  menu,
  fetchCart,
  fetchMenu,
  anonymous,
  isMobileMenuVisible,
  setIsMobileMenuVisible,
  setIsMobileCurrencySelectorVisible,
  hoveredIndex,
  setHoveredIndex,
  hoverTimeout,
  setHoverTimeout,
}) => {
  const [isCurrencySelectorVisible, setIsCurrencySelectorVisible] =
    useState(false);

  const [windowWidth, setWindowWidth] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth;
    }
    return 1200;
  });

  const [hoveredPrimaryIndex, setHoveredPrimaryIndex] = useState(0);
  const [hoveredSecondaryIndex, setHoveredSecondaryIndex] = useState(0);
  const [hoveredTertiaryIndex, setHoveredTertiaryIndex] = useState(0);
  const [tertiaryMenuItems, setTertiaryMenuItems] = useState([]);
  const [tertiaryMenuTitle, setTertiaryMenuTitle] = useState('');
  const [defaultCountries, setDefaultCountries] = useState([]);
  const [selectedMobileMenuIndex, setSelectedMobileMenuIndex] = useState({});

  const handleMenuItemClick = (submenuIndex, itemIndex, url) => {
    setSelectedMobileMenuIndex({
      [submenuIndex]: itemIndex,
    });
    history.push(url);
    setIsMobileMenuVisible(false);
  };

  const currencySelectorRef = useRef(null);
  const desktopMenuRef = useRef(null);

  const fetchCatalogSearch = async (category, country) => {
    try {
      const response = await axios.get(
        `/api/catalog/search?includePromotionMessages=true&q=*%3A*&category=${category}&country=${country}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching catalog search:', error);
      throw error;
    }
  };

  const fetchData = async (category) => {
    try {
      const data = await fetchCatalogSearch(
        category.toLowerCase(),
        Cookies.get('blCountry')
      );
      setTertiaryMenuItems(data.products);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const debouncedFetchData = useCallback(
    debounce(async (category, setTertiaryMenuItems) => {
      try {
        const data = await fetchCatalogSearch(
          category.toLowerCase(),
          Cookies.get('blCountry')
        );
        setTertiaryMenuItems(data.products);
      } catch (error) {
        console.error('Error:', error);
      }
    }, 300),
    []
  );

  const handleResize = () => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);
    }
  };

  const handleClickOutside = (event, ref, callback) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    fetchCart();
    fetchMenu('Header Nav');
  }, [fetchCart, fetchMenu]);

  const findItemWithFullSubmenu = (items) =>
    items?.find((item) => item.submenu && item.submenu.length > 0);

  const handleClickOutsideCurrencySelector = (event) => {
    if (
      currencySelectorRef.current &&
      !currencySelectorRef.current.contains(event.target)
    ) {
      setIsCurrencySelectorVisible(false);
    }
  };

  useEffect(() => {
    if (isCurrencySelectorVisible) {
      document.addEventListener(
        'mousedown',
        handleClickOutsideCurrencySelector
      );
    } else {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCurrencySelector
      );
    }

    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCurrencySelector
      );
    };
  }, [isCurrencySelectorVisible]);
  const handleClickOutsideDesktopMenu = (event) =>
    handleClickOutside(event, desktopMenuRef, () =>
      setIsMobileMenuVisible(false)
    );

  useEffect(() => {
    if (isMobileMenuVisible) {
      document.addEventListener('mousedown', handleClickOutsideDesktopMenu);
      if (defaultCountries.length === 0) {
        fetchLocaleCountries();
      }
    } else {
      document.removeEventListener('mousedown', handleClickOutsideDesktopMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDesktopMenu);
    };
  }, [isMobileMenuVisible, defaultCountries]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const handleMouseEnterPrimaryMenuItem = (index) => {
    setHoveredPrimaryIndex(index);
  };

  const handleMouseEnterSecondaryMenuItem = (index) => {
    setHoveredSecondaryIndex(index);
  };

  const handleMouseEnterTertiaryMenuItem = (index) => {
    setHoveredTertiaryIndex(index);
  };

  useEffect(() => {
    if (hoveredSecondaryIndex !== null) {
      const selectedItem =
        menu &&
        menu['Header Nav']?.menuItems[hoveredIndex]?.submenu[
          hoveredPrimaryIndex
        ]?.submenu[hoveredSecondaryIndex];
      if (selectedItem) {
        setTertiaryMenuTitle(selectedItem.label);
        debouncedFetchData(selectedItem.url.substring(1), setTertiaryMenuItems);
      }
    }
    if (!hoveredSecondaryIndex) {
      setTertiaryMenuItems([]);
    }
  }, [
    hoveredPrimaryIndex,
    menu,
    hoveredIndex,
    hoveredSecondaryIndex,
    debouncedFetchData,
  ]);

  useEffect(() => {
    setHoveredSecondaryIndex(0);
  }, [hoveredPrimaryIndex]);

  const handleMouseEnterMenu = () => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    setIsMobileMenuVisible(true);
  };

  const handleMouseLeaveMenu = () => {
    const timeout = setTimeout(() => {
      setHoveredIndex(null);
      setIsMobileMenuVisible(false);
    }, 200);
    setHoverTimeout(timeout);
  };

  const [submenuStatus, setSubmenuStatus] = useState(
    findItemWithFullSubmenu(
      (menu && menu['Header Nav']?.menuItems) || []
    )?.submenu.reduce((acc, item, index) => {
      acc[index] = 'Default';
      return acc;
    }, {})
  );

  useEffect(() => {
    setSubmenuStatus(
      findItemWithFullSubmenu(
        (menu && menu['Header Nav']?.menuItems) || []
      )?.submenu.reduce((acc, item, index) => {
        acc[index] = 'Default';
        return acc;
      }, {})
    );
  }, [menu]);

  const handleSubmenuClick = (index) => {
    setSubmenuStatus((prevState) => ({
      ...prevState,
      [index]:
        prevState && prevState[index] === 'Default' ? 'Collapsed' : 'Default',
    }));
  };

  const fetchLocaleCountries = async () => {
    try {
      const response = await request.get('/api/menu/localeCountries');
      if (response.body && response.body.localeCountryWrappers) {
        setDefaultCountries(response.body.localeCountryWrappers);
        setActiveCountry(
          response.body.localeCountryWrappers.find(
            (country) => country.countryCode === Cookies.get('blCountry')
          )
        );
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  useEffect(() => {
    if (isMobileMenuVisible && windowWidth <= 1200) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobileMenuVisible, windowWidth]);

  return (
    <>
      {isMobileMenuVisible && windowWidth <= 1200 && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            overflowY: 'auto',
          }}
        >
          <Mobilemenu
            onClose={() => setIsMobileMenuVisible(false)}
            countryLabel={activeCountry?.countryName}
            currencyLabel={''} //{activeCountry?.countryCurrency}
            onCurrencyClick={() => setIsMobileCurrencySelectorVisible(true)}
            icon={<img src="/static/img/HomeIcon.svg" alt="Home icon" />}
            flagplaceholder={
              <ReactCountryFlag
                countryCode={activeCountry?.countryCode}
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                cdnSuffix="svg"
                title={activeCountry?.countryCode}
                style={{
                  borderRadius: '100%',
                  height: '20px',
                  width: '20px',
                  padding: 0,
                }}
              />
            }
            overrides={{
              Sidebar: {
                minHeight: '100%',
              },
              Mobilemenu: {
                width: '100%',
                minHeight: '100%',
              },
              close: {
                style: {
                  cursor: 'pointer',
                  position: 'absolute',
                  right: 20,
                },
              },
              Section4417981: {
                width: '120px',
              },
              MobileMenuitem4419757: {
                style: {
                  cursor: 'pointer',
                },
              },
              MobileMenuitem4419760: {
                style: {
                  cursor: 'pointer',
                },
              },
              MobileMenuitem4419763: {
                style: {
                  cursor: 'pointer',
                },
              },
              label48611745: {
                style: {
                  cursor: 'pointer',
                },
              },
              label48611748: {
                style: {
                  cursor: 'pointer',
                },
              },
              label48611750: {
                style: {
                  cursor: 'pointer',
                },
              },
              label48611752: {
                style: {
                  cursor: 'pointer',
                },
              },
            }}
            profileLabel={anonymous ? 'Sign in' : 'Sign out'}
            onProfileClick={() => {
              setIsMobileMenuVisible(false);
              history.push('/login');
            }}
            onRegisterClick={() => {
              setIsMobileMenuVisible(false);
              history.push('/register');
            }}
            registerDisplay={anonymous ? 'flex' : 'none'}
            onAboutUsClick={() => {
              setIsMobileMenuVisible(false);
              history.push('/#aboutUs');
            }}
            onContactUsClick={() => {
              setIsMobileMenuVisible(false);
              history.push('/contactus');
            }}
            onWishlistClick={() => {
              setIsMobileMenuVisible(false);
              history.push('/account/wishlist');
            }}
            onFaqClick={() => {
              setIsMobileMenuVisible(false);
              history.push('/faq');
            }}
            slot={
              <>
                <div
                  style={{
                    lineHeight: '21px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: '#3D0C00',
                    fontFamily: 'Montserrat',
                  }}
                >
                  {
                    findItemWithFullSubmenu(
                      menu && menu['Header Nav']?.menuItems
                    )?.label
                  }
                </div>
                {findItemWithFullSubmenu(
                  menu && menu['Header Nav']?.menuItems
                )?.submenu.map((item, index) => (
                  <div key={index}>
                    <Submenu
                      label={item.label}
                      status={submenuStatus ? submenuStatus[index] : 'Default'}
                      onClick={() => handleSubmenuClick(index)}
                      overrides={{
                        Submenu: {
                          style: {
                            cursor: 'pointer',
                          },
                        },
                      }}
                    />
                    <CSSTransition
                      in={submenuStatus && submenuStatus[index] === 'Default'}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 20,
                        }}
                      >
                        {item.submenu.map((subItem, subIndex) => (
                          <MobileMenuItem
                            key={subIndex}
                            label={subItem.label}
                            href={subItem.url}
                            as="a"
                            onClick={() =>
                              handleMenuItemClick(index, subIndex, subItem.url)
                            }
                            overrides={{
                              MobileMenuitem: {
                                backgroundColor:
                                  selectedMobileMenuIndex[index] !== subIndex
                                    ? 'transparent'
                                    : 'rgb(168, 104, 0)',
                                style: {
                                  cursor: 'pointer',
                                  backgroundColor:
                                    selectedMobileMenuIndex[index] !== subIndex
                                      ? 'transparent'
                                      : 'rgb(168, 104, 0)',
                                },
                              },
                              label: {
                                width: '100%',
                                color:
                                  selectedMobileMenuIndex[index] !== subIndex
                                    ? '#6A3316'
                                    : '#ffffff',
                              },
                            }}
                          />
                        ))}
                      </div>
                    </CSSTransition>
                  </div>
                ))}
              </>
            }
          />
        </div>
      )}
      {isMobileMenuVisible && hoveredIndex !== null && windowWidth > 1200 && (
        <div
          ref={desktopMenuRef}
          style={{
            position: 'absolute',
            top: 110,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            overflow: 'hidden',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          }}
          onMouseEnter={handleMouseEnterMenu}
          onMouseLeave={handleMouseLeaveMenu}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Primarymenu
              onClose={() => setIsMobileMenuVisible(false)}
              overrides={{
                Desktopmenu: {
                  width: '100%',
                  gap: '30px',
                  flexWrap: 'wrap',
                },
                close: {
                  style: {
                    cursor: 'pointer',
                  },
                },
                slot: {
                  width: '100%',
                },
              }}
              title={menu && menu['Header Nav']?.menuItems[hoveredIndex]?.label}
              slot={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                  }}
                >
                  {menu &&
                    menu['Header Nav']?.menuItems[hoveredIndex]?.submenu.map(
                      (submenuItem, index) => (
                        <MobileMenuItem
                          onClick={() => {
                            setIsMobileMenuVisible(false);
                            history.push(submenuItem.url);
                          }}
                          key={submenuItem.url}
                          label={submenuItem.label}
                          overrides={{
                            MobileMenuitem: {
                              backgroundColor:
                                hoveredPrimaryIndex !== index
                                  ? 'transparent'
                                  : 'rgb(168, 104, 0)',
                              style: {
                                cursor: 'pointer',
                                backgroundColor:
                                  hoveredPrimaryIndex !== index
                                    ? 'transparent'
                                    : 'rgb(168, 104, 0)',
                              },
                            },
                            label: {
                              width: '100%',
                              color:
                                hoveredPrimaryIndex !== index
                                  ? '#6A3316'
                                  : '#ffffff',
                            },
                          }}
                          onMouseEnter={() =>
                            handleMouseEnterPrimaryMenuItem(index)
                          }
                          state={
                            hoveredPrimaryIndex === index
                              ? 'Selected'
                              : 'Default'
                          }
                        />
                      )
                    )}
                </div>
              }
            />
            <Secondarymenu
              overrides={{
                slot: {
                  width: '100%',
                },
              }}
              title={
                hoveredPrimaryIndex !== null
                  ? menu &&
                    menu['Header Nav']?.menuItems[hoveredIndex]?.submenu[
                      hoveredPrimaryIndex
                    ]?.label
                  : menu && menu['Header Nav']?.menuItems[0]?.label
              }
              slot={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                  }}
                >
                  {hoveredPrimaryIndex !== null
                    ? menu &&
                      menu['Header Nav']?.menuItems[hoveredIndex]?.submenu[
                        hoveredPrimaryIndex
                      ]?.submenu.map((submenuItem, index) => (
                        <MobileMenuItem
                          key={submenuItem.url}
                          label={submenuItem.label}
                          onClick={() => {
                            setIsMobileMenuVisible(false);
                            history.push(submenuItem.url);
                          }}
                          onMouseEnter={() =>
                            handleMouseEnterSecondaryMenuItem(index)
                          }
                          state={
                            hoveredSecondaryIndex === index
                              ? 'Selected'
                              : 'Default'
                          }
                          overrides={{
                            MobileMenuitem: {
                              backgroundColor:
                                hoveredSecondaryIndex !== index
                                  ? 'transparent'
                                  : 'rgb(168, 104, 0)',
                              style: {
                                cursor: 'pointer',
                                backgroundColor:
                                  hoveredSecondaryIndex !== index
                                    ? 'transparent'
                                    : 'rgb(168, 104, 0)',
                              },
                            },
                            label: {
                              width: '100%',
                              color:
                                hoveredSecondaryIndex !== index
                                  ? '#6A3316'
                                  : '#ffffff',
                            },
                          }}
                        />
                      ))
                    : menu &&
                      menu['Header Nav']?.menuItems[0]?.submenu.map(
                        (submenuItem, index) => (
                          <MobileMenuItem
                            key={submenuItem.url}
                            label={submenuItem.label}
                            onMouseEnter={() =>
                              handleMouseEnterSecondaryMenuItem(index)
                            }
                            state={
                              hoveredSecondaryIndex === index
                                ? 'Selected'
                                : 'Default'
                            }
                            overrides={{
                              MobileMenuitem: {
                                backgroundColor:
                                  hoveredSecondaryIndex !== index
                                    ? 'transparent'
                                    : 'rgb(168, 104, 0)',
                                style: {
                                  cursor: 'pointer',
                                  backgroundColor:
                                    hoveredSecondaryIndex !== index
                                      ? 'transparent'
                                      : 'rgb(168, 104, 0)',
                                },
                              },
                              label: {
                                width: '100%',
                                color:
                                  hoveredSecondaryIndex !== index
                                    ? '#6A3316'
                                    : '#ffffff',
                              },
                            }}
                          />
                        )
                      )}
                </div>
              }
            />
            <Tertiarymenu
              title={tertiaryMenuTitle}
              overrides={{
                Tertiarymenu: {
                  style: {
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  },
                },
                Section: {
                  width: '100%',
                },
                slot: {
                  width: '100%',
                },
              }}
              slot={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 20,
                  }}
                >
                  {tertiaryMenuItems?.map((item, index) => (
                    <MobileMenuItem
                      key={item.url}
                      label={item.name}
                      href={item.url}
                      onClick={() => {
                        history.push(item.url);
                        setIsMobileMenuVisible(false);
                      }}
                      onMouseEnter={() =>
                        handleMouseEnterTertiaryMenuItem(index)
                      }
                      state={
                        hoveredTertiaryIndex === index ? 'Selected' : 'Default'
                      }
                      overrides={{
                        slot: {
                          width: '100%',
                        },
                        MobileMenuitem: {
                          display: 'block',
                          backgroundColor:
                            hoveredTertiaryIndex !== index
                              ? 'transparent'
                              : 'rgb(168, 104, 0)',
                          style: {
                            cursor: 'pointer',
                            backgroundColor:
                              hoveredTertiaryIndex !== index
                                ? 'transparent'
                                : 'rgb(168, 104, 0)',
                          },
                        },
                        label: {
                          width: '100%',
                          color:
                            hoveredTertiaryIndex !== index
                              ? '#6A3316'
                              : '#ffffff',
                        },
                      }}
                    />
                  ))}
                </div>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderMenu;
