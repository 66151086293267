/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function TabLabel(props) {
  const { onClick, label, state, overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { Text: {}, TabLabel: {} },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        Text: {},
        TabLabel: {
          direction: "column",
          justifyContent: "center",
          borderRadius: "8px",
          backgroundColor: "rgba(255,219,168,1)",
        },
      },
      variantValues: { state: "Onselect" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="row"
      width="108px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      padding="16px 16px 16px 16px"
      display="flex"
      onClick={onClick}
      {...getOverrideProps(overrides, "TabLabel")}
      {...rest}
    >
      <Text
        fontFamily="Caveat Brush"
        fontSize="30px"
        fontWeight="400"
        color="rgba(139,89,63,1)"
        lineHeight="38px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.68px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children={label}
        {...getOverrideProps(overrides, "Text")}
      ></Text>
    </Flex>
  );
}
