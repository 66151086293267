import React, { useState, useEffect, useRef } from 'react';
import FreeshippingindicatorminiWrapper from './FreeshippingindicatorminiWrapper';
import Cartoverview from '../../../ui-components/Cartoverview';
import QuickNavigation from '../../../ui-components/QuickNavigation';
import BottomWidgetStyle from './BottomWidget.module.scss';
import classNames from 'classnames';
import MiniCartPopup from '../MiniCartPopup';
import HeaderMenu from '../HeaderMenu';
import Currencyselector from '../../../ui-components/Currencyselector';
import LocaleCountrySelector from '../CountrySelector/LocaleCountrySelector';
import request from 'core/util/superagent';
import Cookies from 'js-cookie';

const BottomWidget = ({
  cartAmount,
  cartItemsSize,
  history,
  anonymous,
  cart,
  removeFromCart,
  updateQuantity,
  fetchMenu,
  fetchCart,
  menu
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const [defaultCountries, setDefaultCountries] = useState([]);
  const [activeCountry, setActiveCountry] = useState(null);
  const [isMobileCurrencySelectorVisible, setIsMobileCurrencySelectorVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const cartRef = useRef(null);
  const notePopupRef = useRef(null);
  const cartIconRef = useRef(null);
  const currencySelectorRef = useRef(null);

  const fetchLocaleCountries = async () => {
    try {
      const response = await request.get('/api/menu/localeCountries');
      if (response.body && response.body.localeCountryWrappers) {
        setDefaultCountries(response.body.localeCountryWrappers);
        setActiveCountry(
          response.body.localeCountryWrappers.find(
            (country) => country.countryCode === Cookies.get('blCountry')
          )
        );
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const handleOnShopIconClick = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const handleOnCartIconClick = () => {
    setIsCartVisible(!isCartVisible);
  };

  const handleClickOutsideCart = (event) => {
    if (
      cartRef.current &&
      !cartRef.current.contains(event.target) &&
      ((notePopupRef.current && !notePopupRef.current.contains(event.target)) ||
        !notePopupRef.current)
    ) {
      setIsCartVisible(false);
    }
  };

  useEffect(() => {
    if (isCartVisible) {
      document.addEventListener('mousedown', handleClickOutsideCart);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    };
  }, [isCartVisible]);

  useEffect(() => {
    if (isMobileMenuVisible) {
      if (defaultCountries.length === 0) {
        fetchLocaleCountries();
      }
    }
  }, [isMobileMenuVisible]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        const header = document.getElementById('headerId');
        if (header) {
          const rect = header.getBoundingClientRect();
          if (rect.bottom < 0) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
            setIsCartVisible(false);
          }
        }
      };

      const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
      };

      window.addEventListener('scroll', handleScroll);
      window.addEventListener('resize', handleResize);

      handleResize();

      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const currencySelectorOverrides = {
    close: {
      style: {
        cursor: 'pointer',
      },
    },
  };

  const quickNavigationOverrides = {
    QuickNavigation: {
      width: '100%',
      style: {
        width: '100%',
        maxWidth: '752px',
      },
    },
    Button48131911: {
      style: {
        cursor: 'pointer',
      },
    },
    Button48131905: {
      style: {
        cursor: 'pointer',
      },
    },
    Button48131908: {
      style: {
        cursor: 'pointer',
      },
    }
  };

  const cartOverviewOverrides = {
    Cartoverview: {
      width: '100%',
      style: {
        width: '100%',
        maxWidth: '752px',
      },
    },
  };

  const handleOnProfileIconClick = () => {
    history.push(anonymous ? '/login' : '/account');
  };

  const handleOnHelpIconClick = () => {
    window.open('https://api.whatsapp.com/send?phone=353899569854')
  };

  const isBottomWidgetHidden = typeof window !== 'undefined' && 
    (window.location.href.includes('cart') || window.location.href.includes('checkout'));

  return (!isBottomWidgetHidden && (
    <div className={classNames(
      BottomWidgetStyle.bottomWidget,
      isVisible ? BottomWidgetStyle.widgetVisble : ''
    )}>
      <FreeshippingindicatorminiWrapper
        cartAmount={cartAmount && cartAmount.toFixed(2)}
      />
      <Cartoverview
        onCartIconClick={handleOnCartIconClick}
        shoppingCartSrc="/static/img/local_mallWhite.svg"
        overrides={cartOverviewOverrides}
        cartItemsSize={cartItemsSize}
        cartAmount={cartAmount != null ? cartAmount.toFixed(2) : undefined}
      />
      {isMobile && (
        <QuickNavigation
          onProfileIconClick={handleOnProfileIconClick}
          onHelpIconClick={handleOnHelpIconClick}
          onShopIconClick={handleOnShopIconClick}
          overrides={quickNavigationOverrides}
          profileImgSrc="/static/img/account_circle.svg"
          helpImgSrc="static/img/whatsapp.svg"
          shopImgSrc="/static/img/storefront.svg"
        />
      )}
      {isCartVisible && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
          }}
        >
          <MiniCartPopup
            cart={cart}
            removeFromCart={removeFromCart}
            updateQuantity={updateQuantity}
            fetchCart={fetchCart}
            history={history}
            isCartVisible={isCartVisible}
            setIsCartVisible={setIsCartVisible}
          />
        </div>
      )}
      <HeaderMenu
        activeCountry={activeCountry}
        setActiveCountry={setActiveCountry}
        history={history}
        menu={menu}
        fetchCart={fetchCart}
        fetchMenu={fetchMenu}
        anonymous={anonymous}
        isMobileMenuVisible={isMobileMenuVisible}
        setIsMobileMenuVisible={setIsMobileMenuVisible}
        setIsMobileCurrencySelectorVisible={setIsMobileCurrencySelectorVisible}
      />
      {isMobileCurrencySelectorVisible && (
        <div
          ref={currencySelectorRef}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2000,
          }}
        >
          <Currencyselector
            onClose={() => setIsMobileCurrencySelectorVisible(false)}
            onSearchChange={(e) => setSearchTerm(e.target.value)}
            slot={
              <LocaleCountrySelector
                searchTerm={searchTerm}
                overrides={{
                  CounryselectorMenuitem: {
                    width: '100%',
                  },
                  Content: {
                    width: '100%',
                  },
                }}
              />
            }
            overrides={{
              ...currencySelectorOverrides,
              Currencyselector: {
                width: '100%',
                height: '100%',
              },
              content: {
                width: '100%',
              },
              slot: {
                width: '100%',
              },
              close: {
                left: 'auto',
                right: '0',
              },
              Divider: {
                width: '100%',
              },
              'Choose a country or region. This affects the currency you\u2019re billed in, availability of items, price and delivery options.':
              {
                width: '100%',
              },
              SearchField: {
                width: '100%',
              },
            }}
            placeholder="Search for ‘Khapli wheat’"
          />
        </div>
      )}
    </div>));
};

export default BottomWidget;
