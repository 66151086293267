/*
 * #%L
 * React Site Starter
 * %%
 * Copyright (C) 2009 - 2017 Broadleaf Commerce
 * %%
 * Broadleaf Commerce React Starter
 *
 * Written in 2017 by Broadleaf Commerce info@broadleafcommerce.com
 *
 * To the extent possible under law, the author(s) have dedicated all copyright and related and neighboring rights to this software to the public domain worldwide. This software is distributed without any warranty.
 * You should have received a copy of the CC0 Public Domain Dedication along with this software. If not, see <http://creativecommons.org/publicdomain/zero/1.0/>.
 *
 * Please Note - The scope of CC0 Public Domain Dedication extends to Broadleaf Commerce React Starter demo application alone. Linked libraries (including all Broadleaf Commerce Framework libraries) are subject to their respective licenses, including the requirements and restrictions specified therein.
 * #L%
 */
import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import Header from '../../../ui-components/Header';
import Currencyselector from '../../../ui-components/Currencyselector';
import MiniCartPopup from '../MiniCartPopup';
import Searchoverlay from '../../../ui-components/Searchoverlay';
import LocaleCountrySelector from '../CountrySelector/LocaleCountrySelector';
import HeaderMenu from '../HeaderMenu';
import map from 'lodash/map';
import GlobalScss from '../../../layout/style/common.scss';
import Cookies from 'js-cookie';
import request from 'core/util/superagent';
import FullPageLayoutStyle from 'layout/components/FullPageLayout/FullPageLayout.module.scss';
import classNames from 'classnames';

const NavLink = forwardRef((props, ref) => (
  <RouterNavLink innerRef={ref} {...props} />
));

const searchOverlayOverrides = {
  Searchoverlay: {
    width: '100%',
  },
  Button: {
    style: {
      cursor: 'pointer',
    },
  },
  Input: {
    style: {
      cursor: 'text',
    },
  },
};

const currencySelectorOverrides = {
  close: {
    style: {
      cursor: 'pointer',
    },
  },
  slot: {
    style: {
      overflow: 'initial',
      maxHeight: '300px',
      'overflow-y': 'auto',
    },
  },
};

const headerOverrides = {
  Header: {
    width: '100%',
    gap: 'initial',
    style: {
      width: '100%',
      maxWidth: '1440px',
    },
  },
  Logo: {
    style: {
      cursor: 'pointer',
    },
  },
  search: {
    style: {
      cursor: 'pointer',
    },
  },
  favorite: {
    style: {
      cursor: 'pointer',
    },
  },
  CurrencySwitcher: {
    style: {
      cursor: 'pointer',
    },
  },
  account_circle: {
    style: {
      cursor: 'pointer',
    },
  },
  local_mall: {
    style: {
      cursor: 'pointer',
    },
  },
  menu: {
    style: {
      cursor: 'pointer',
    },
  },
  NavLinks: {
    overrides: {
      TextLink4365419: {
        style: {
          cursor: 'pointer',
        },
      },
      TextLink4365420: {
        style: {
          cursor: 'pointer',
        },
      },
      TextLink4365421: {
        style: {
          cursor: 'pointer',
        },
      },
      TextLink4365422: {
        style: {
          cursor: 'pointer',
        },
      },
      TextLink4365423: {
        style: {
          cursor: 'pointer',
        },
      },
    },
  },
  Image_search: {
    style: {
      cursor: 'pointer',
    },
  },
  Image_favorite: {
    style: {
      cursor: 'pointer',
    },
  },
  Image_currency_switcher: {
    style: {
      cursor: 'pointer',
    },
  },
  Image_account_circle: {
    style: {
      cursor: 'pointer',
    },
  },
  Image_local_mall: {
    style: {
      cursor: 'pointer',
    },
  },
  Image_menu: {
    style: {
      cursor: 'pointer',
    },
  },
};

export const RDHeader = ({
  cart,
  removeFromCart,
  updateQuantity,
  history,
  menu,
  fetchCart,
  fetchMenu,
  anonymous,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const worldIconRef = useRef(null);
  const searchIconRef = useRef(null);
  const cartIconRef = useRef(null);
  const notePopupRef = useRef(null);
  const mobileMenuIconRef = useRef(null);
  const [searchOverlayText, setSearchOverlayText] = useState('');
  const [isCurrencySelectorVisible, setIsCurrencySelectorVisible] =
    useState(false);
  const [isMobileCurrencySelectorVisible, setIsMobileCurrencySelectorVisible] =
    useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [isSearchOverlayVisible, setIsSearchOverlayVisible] = useState(false);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const [divPosition, setDivPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    fetchCart();
    fetchMenu('Header Nav');
  }, [fetchCart, fetchMenu]);

  const handleOnHeartClick = () => {
    history.push('/account/wishlist');
  };

  const handleOnProfileIconClick = () => {
    history.push(anonymous ? '/login' : '/account');
  };

  const handleOnSearchIconClick = () => {
    if (searchIconRef.current && headerRef.current) {
      const rect = headerRef.current.getBoundingClientRect();
      setDivPosition({
        top: rect.top,
        left: 0,
        right: 0,
      });
      setIsSearchOverlayVisible(!isSearchOverlayVisible);
    }
  };
  const handleOnCartIconClick = () => {
    if (cartIconRef.current) {
      const rect = cartIconRef.current.getBoundingClientRect();
      setDivPosition({
        top: rect.top,
        left: rect.left - 320,
      });
      setIsCartVisible(!isCartVisible);
    }
  };

  const handleOnWorldIconClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (worldIconRef.current) {
      const rect = worldIconRef.current.getBoundingClientRect();
      setDivPosition({
        top: rect.top,
        left: rect.left - 320,
      });
      setIsCurrencySelectorVisible(!isCurrencySelectorVisible);
    }
  };

  const handleOnMenuIconClick = () => {
    if (mobileMenuIconRef.current) {
      setDivPosition({
        top: 0,
        left: 0,
        right: 0,
      });
      setIsMobileMenuVisible(!isMobileMenuVisible);
    }
  };

  const searchOverlayRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      searchOverlayRef.current &&
      !searchOverlayRef.current.contains(event.target)
    ) {
      setIsSearchOverlayVisible(false);
    }
  };

  useEffect(() => {
    if (isSearchOverlayVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSearchOverlayVisible]);

  const cartRef = useRef(null);

  const handleClickOutsideCart = (event) => {
    if (
      cartRef.current &&
      !cartRef.current.contains(event.target) &&
      ((notePopupRef.current && !notePopupRef.current.contains(event.target)) ||
        !notePopupRef.current)
    ) {
      setIsCartVisible(false);
    }
  };

  useEffect(() => {
    if (isCartVisible) {
      document.addEventListener('mousedown', handleClickOutsideCart);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    };
  }, [isCartVisible]);

  useEffect(() => {
    if (isCartVisible) {
      document.addEventListener('mousedown', handleClickOutsideCart);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideCart);
    };
  }, [isCartVisible]);

  const currencySelectorRef = useRef(null);

  const handleClickOutsideCurrencySelector = (event) => {
    if (
      currencySelectorRef.current &&
      !currencySelectorRef.current.contains(event.target)
    ) {
      setIsCurrencySelectorVisible(false);
    }
  };

  useEffect(() => {
    if (isCurrencySelectorVisible) {
      document.addEventListener(
        'mousedown',
        handleClickOutsideCurrencySelector
      );
    } else {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCurrencySelector
      );
    }

    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCurrencySelector
      );
    };
  }, [isCurrencySelectorVisible]);

  const desktopMenuRef = useRef(null);

  const handleClickOutsideDesktopMenu = (event) => {
    if (
      desktopMenuRef.current &&
      !desktopMenuRef.current.contains(event.target)
    ) {
      setIsMobileMenuVisible(false);
    }
  };

  useEffect(() => {
    if (isMobileMenuVisible) {
      document.addEventListener('mousedown', handleClickOutsideDesktopMenu);
      if (defaultCountries.length === 0) {
        fetchLocaleCountries();
      }
    } else {
      document.removeEventListener('mousedown', handleClickOutsideDesktopMenu);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDesktopMenu);
    };
  }, [isMobileMenuVisible]);

  const navLinkRefs = useRef([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const headerRef = useRef(null);
  const [hoverTimeout, setHoverTimeout] = useState(null);

  const handleMouseEnter = (index) => {
    if (hoverTimeout) {
      clearTimeout(hoverTimeout);
      setHoverTimeout(null);
    }
    const navLinkRef = navLinkRefs.current[index];
    if (
      navLinkRef &&
      menu &&
      menu['Header Nav']?.menuItems[index]?.submenu?.length
    ) {
      setHoveredIndex(index);
      setIsMobileMenuVisible(true);
    }
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setHoveredIndex(null);
      setIsMobileMenuVisible(false);
    }, 200);
    setHoverTimeout(timeout);
  };

  const [defaultCountries, setDefaultCountries] = useState([]);
  const [activeCountry, setActiveCountry] = useState(null);

  const fetchLocaleCountries = async () => {
    try {
      const response = await request.get('/api/menu/localeCountries');
      if (response.body && response.body.localeCountryWrappers) {
        setDefaultCountries(response.body.localeCountryWrappers);
        setActiveCountry(
          response.body.localeCountryWrappers.find(
            (country) => country.countryCode === Cookies.get('blCountry')
          )
        );
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const AnimatedPlaceholderInput = () => {
    const suggestions = ['Khapli wheat', 'Basmati rice', 'Quinoa', 'Chickpea'];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
      const interval = setInterval(() => {
        setAnimate(true);
        setTimeout(() => {
          setAnimate(false);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
        }, 500);
      }, 3000);

      return () => clearInterval(interval);
    }, []);

    return (
      <div className={FullPageLayoutStyle.animatedSearchInputWrapper}>
        <input
          // placeholder={`Search for ${suggestions[currentIndex]}`}
          value={searchOverlayText}
          onChange={(e) => setSearchOverlayText(e.target.value)}
          className={FullPageLayoutStyle.searchOverlayInput}
          autoFocus
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              history.push(`/browse?q=${searchOverlayText}`);
            }
          }}
        />
        {!searchOverlayText ? (
          <span
            aria-hidden="true"
            className={FullPageLayoutStyle.placeholderText}
          >
            Search for{' '}
            <span
              className={classNames(animate && FullPageLayoutStyle.animate)}
              aria-hidden="true"
            >
              {suggestions[currentIndex]}
            </span>
          </span>
        ) : null}
      </div>
    );
  };

  const [windowWidth, setWindowWidth] = useState(1279); // Postavite podrazumevanu vrednost

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);
    }
  }, []);

  const handleResize = () => {
    if (typeof window !== 'undefined') {
      setWindowWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return (
    <div className={GlobalScss.GridWrapper} ref={headerRef}>
      <Header
        id="headerId"
        overrides={{
          ...headerOverrides,
          CartBadge: {
            style: {
              cursor: 'pointer',
              position: 'absolute',
              right: windowWidth <= 1278 ? '105px' : '60px',
              top: '12px',
            },
          },
        }}
        logoSrc="/static/img/Logo_Rdfood_Final.svg"
        searchIconSrc="/static/img/search_icon.svg"
        heartIconSrc="/static/img/favorite.svg"
        worldIconSrc="/static/img/currency_switcher.svg"
        accountIconSrc="/static/img/account_circle.svg"
        cartIconSrc="/static/img/local_mall.svg"
        menuIconSrc="/static/img/menu.svg"
        worldIconRef={worldIconRef}
        searchIconRef={searchIconRef}
        onHeartIconClick={handleOnHeartClick}
        onSearchIconClick={handleOnSearchIconClick}
        onCartIconClick={handleOnCartIconClick}
        onCartBadgeClick={handleOnCartIconClick}
        onWorldIconClick={handleOnWorldIconClick}
        onMenuIconClick={handleOnMenuIconClick}
        cartIconRef={cartIconRef}
        mobileMenuIconRef={mobileMenuIconRef}
        onProfileIconClick={handleOnProfileIconClick}
        onLogoClick={() => history.push('/')}
        cartBadgeAmount={cart?.itemCount}
        isCartBadgeVisible={cart?.itemCount > 0}
        isCartIconHidden={
          typeof window !== 'undefined' &&
          (window?.location?.href.includes('cart') ||
            window?.location?.href.includes('checkout'))
        }
        navLinks={
          <div
            style={{
              gap: '32px',
              flexDirection: 'row',
              width: '585px',
              height: 'unset',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              padding: '0px',
              display: 'flex',
              flexShrink: '0',
            }}
          >
            {map(menu && menu['Header Nav']?.menuItems, (item, index) => {
              const navLinkRef = navLinkRefs.current[index];
              return (
                <NavLink
                  key={item.url}
                  ref={(el) => (navLinkRefs.current[index] = el)}
                  to={item.url}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                >
                  {item.label}
                  {navLinkRef &&
                  menu &&
                  menu['Header Nav']?.menuItems[index]?.submenu?.length ? (
                    <img
                      src="/static/img/Dropdown.svg"
                      alt="dropdown"
                      style={{
                        transform: isMobileMenuVisible
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                        transition: 'transform 0.3s ease-in-out',
                      }}
                    />
                  ) : null}
                </NavLink>
              );
            })}
          </div>
        }
      />
      {isCurrencySelectorVisible && worldIconRef.current && (
        <div
          ref={currencySelectorRef}
          style={{
            position: 'absolute',
            top: divPosition.top,
            left: divPosition.left,
            zIndex: 1000,
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
          }}
        >
          <Currencyselector
            onClose={() => setIsCurrencySelectorVisible(false)}
            onSearchChange={(e) => setSearchTerm(e.target.value)}
            slot={<LocaleCountrySelector searchTerm={searchTerm} />}
            overrides={currencySelectorOverrides}
            placeholder="Search for ‘Khapli wheat’"
          />
        </div>
      )}
      {isMobileCurrencySelectorVisible && (
        <div
          ref={currencySelectorRef}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 2000,
          }}
        >
          <Currencyselector
            onClose={() => setIsMobileCurrencySelectorVisible(false)}
            onSearchChange={(e) => setSearchTerm(e.target.value)}
            slot={
              <LocaleCountrySelector
                searchTerm={searchTerm}
                overrides={{
                  CounryselectorMenuitem: {
                    width: '100%',
                  },
                  Content: {
                    width: 'calc(100% - 34px)',
                  },
                }}
              />
            }
            overrides={{
              ...currencySelectorOverrides,
              Currencyselector: {
                width: 'calc(100% - 24px)',
                height: '100%',
              },
              content: {
                width: '100%',
              },
              slot: {
                width: '100%',
                overflow: 'auto',
                style: {
                  maxHeight: '70vh',
                },
              },
              close: {
                left: 'auto',
                right: '0',
              },
              Divider: {
                width: '100%',
              },
              'Choose a country or region. This affects the currency you\u2019re billed in, availability of items, price and delivery options.':
                {
                  width: '100%',
                },
              SearchField: {
                width: '100%',
              },
            }}
            placeholder="Search for ‘Khapli wheat’"
          />
        </div>
      )}
      {isSearchOverlayVisible && searchIconRef.current && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
          }}
        >
          <div
            ref={searchOverlayRef}
            style={{
              position: 'absolute',
              top: divPosition.top,
              left: 0,
              right: 0,
              zIndex: 1000,
            }}
          >
            <Searchoverlay
              overrides={searchOverlayOverrides}
              onClose={() => setIsSearchOverlayVisible(false)}
              placeholder={searchOverlayText}
              inputGroup={<AnimatedPlaceholderInput />}
            />
          </div>
        </div>
      )}
      {isCartVisible && cartIconRef.current && (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 999,
          }}
        >
          <MiniCartPopup
            cart={cart}
            removeFromCart={removeFromCart}
            updateQuantity={updateQuantity}
            fetchCart={fetchCart}
            history={history}
            isCartVisible={isCartVisible}
            setIsCartVisible={setIsCartVisible}
          />
        </div>
      )}
      <HeaderMenu
        activeCountry={activeCountry}
        setActiveCountry={setActiveCountry}
        history={history}
        menu={menu}
        fetchCart={fetchCart}
        fetchMenu={fetchMenu}
        anonymous={anonymous}
        isMobileMenuVisible={isMobileMenuVisible}
        setIsMobileMenuVisible={setIsMobileMenuVisible}
        setIsMobileCurrencySelectorVisible={setIsMobileCurrencySelectorVisible}
        hoveredIndex={hoveredIndex}
        setHoveredIndex={setHoveredIndex}
        hoverTimeout={hoverTimeout}
        setHoverTimeout={setHoverTimeout}
      />
    </div>
  );
};

export default RDHeader;
