/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import Announcementbar from "./Announcementbar";
import { Flex } from "@aws-amplify/ui-react";
export default function Anouncementbarfinal(props) {
  const {
    lines,
    announcmentMsg1,
    announcmentMsg2,
    onPrevBtnClick,
    onNextBtnClick,
    overrides,
    ...rest
  } = props;
  return (
    <Flex
      gap="8px"
      direction="column"
      width="387px"
      height="56px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="4px 2px 4px 2px"
      backgroundColor="rgba(0,51,17,1)"
      {...getOverrideProps(overrides, "Anouncementbarfinal")}
      {...rest}
    >
      <Announcementbar
        display="flex"
        gap="64px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="space-between"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 24px 0px 24px"
        backgroundColor="rgba(0,51,17,1)"
        lines={lines}
        announcmentMsg1={announcmentMsg1}
        announcmentMsg2={announcmentMsg2}
        onPrevBtnClick={onPrevBtnClick}
        onNextBtnClick={onNextBtnClick}
        {...getOverrideProps(overrides, "Announcementbar")}
      ></Announcementbar>
    </Flex>
  );
}
