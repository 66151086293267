/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps, useNavigateAction } from "./utils";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function Condimentscontentmobile(props) {
  const {
    image,
    leftArrow,
    rightArrow,
    firstDot,
    secondDot,
    thirdDot,
    onLeftClick,
    onRightClick,
    sliderImage,
    overrides,
    ...rest
  } = props;
  const buttonFiveFourOneEightOneSixZeroOneOnClick = useNavigateAction({
    type: "url",
    url: "/condiments",
  });
  return (
    <Flex
      gap="16px"
      direction="column"
      width="288px"
      height="unset"
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Condimentscontentmobile")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Text
          fontFamily="Montserrat"
          fontSize="20px"
          fontWeight="700"
          color="rgba(21,71,38,1)"
          lineHeight="24px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="0.85px"
          width="unset"
          height="27px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Condiments"
          {...getOverrideProps(overrides, "Condiments")}
        ></Text>
        <Text
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="500"
          color="rgba(21,71,38,1)"
          lineHeight="33px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          letterSpacing="1.05px"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="A delightful selection of pickles, spicy sprinkles, instant mixes and more! Whether you’re enhancing a dish with our tangy pickles, adding a burst of spice with dry toppings, or preparing traditional recipes effortlessly with instant mixes, we ensure each product is crafted to preserve its natural taste and quality."
          {...getOverrideProps(
            overrides,
            "A delightful selection of pickles, spicy sprinkles, instant mixes and more! Whether you\u2019re enhancing a dish with our tangy pickles, adding a burst of spice with dry toppings, or preparing traditional recipes effortlessly with instant mixes, we ensure each product is crafted to preserve its natural taste and quality."
          )}
        ></Text>
      </Flex>
      <Flex
        gap="24px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Container")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="293px"
          height="510px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Bottom")}
        >
          <Flex
            gap="16px"
            direction="column"
            width="unset"
            height="456px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 501")}
          >
            <Flex
              gap="-164px"
              direction="column"
              width="216px"
              height="unset"
              justifyContent="flex-start"
              alignItems="flex-start"
              shrink="0"
              position="relative"
              padding="8px 8px 8px 8px"
              {...getOverrideProps(overrides, "Image52311786")}
            >
              <Image
                width="200px"
                height="188.41px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={image}
                {...getOverrideProps(overrides, "Image52311787")}
              ></Image>
            </Flex>
            <Flex
              gap="72px"
              direction="row"
              width="unset"
              height="unset"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="8px 8px 8px 8px"
              children={sliderImage}
              {...getOverrideProps(overrides, "SliderImage")}
            ></Flex>
            <Flex
              gap="20px"
              direction="row"
              width="280px"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 50052311789")}
            >
              <Image
                width="32px"
                height="22px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={firstDot}
                {...getOverrideProps(overrides, "Image52311790")}
              ></Image>
              <Text
                fontFamily="Montserrat"
                fontSize="14px"
                fontWeight="500"
                color="rgba(21,71,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="1.05px"
                width="225px"
                height="21px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Only real ingredients."
                {...getOverrideProps(overrides, "Only real ingredients.")}
              ></Text>
            </Flex>
            <Flex
              gap="20px"
              direction="row"
              width="280px"
              height="54px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 50052311792")}
            >
              <Image
                width="32px"
                height="33px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={secondDot}
                {...getOverrideProps(overrides, "Image52311793")}
              ></Image>
              <Text
                fontFamily="Montserrat"
                fontSize="14px"
                fontWeight="500"
                color="rgba(21,71,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="1.05px"
                width="225px"
                height="46px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="No added preservatives or artificial flavors."
                {...getOverrideProps(
                  overrides,
                  "No added preservatives or artificial flavors."
                )}
              ></Text>
            </Flex>
            <Flex
              gap="20px"
              direction="row"
              width="280px"
              height="48px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 50052311795")}
            >
              <Image
                width="32px"
                height="35px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                objectFit="cover"
                src={thirdDot}
                {...getOverrideProps(overrides, "Image52311796")}
              ></Image>
              <Text
                fontFamily="Montserrat"
                fontSize="14px"
                fontWeight="500"
                color="rgba(21,71,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="1.05px"
                width="230px"
                height="46px"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Supports immunity & digestion. "
                {...getOverrideProps(
                  overrides,
                  "Supports immunity & digestion."
                )}
              ></Text>
            </Flex>
          </Flex>
          <Flex
            gap="29px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-start"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Button52311798")}
          >
            <Button
              width="unset"
              height="unset"
              shrink="0"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Browse Condiments"
              onClick={() => {
                buttonFiveFourOneEightOneSixZeroOneOnClick();
              }}
              {...getOverrideProps(overrides, "Button54181601")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
