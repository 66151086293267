import React, { useState, useEffect, useRef } from 'react';
import KindOfFoodStyle from './KindOfFood.module.scss';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import ReactSwipe from 'react-swipe';
import Stonemilledflours from '../../../../ui-components/Stonemilledflours';
import Snacks from '../../../../ui-components/Snacks';
import Pulses from '../../../../ui-components/Pulses';
import Indianspices from '../../../../ui-components/Indianspices';
import Coldpressedoils from '../../../../ui-components/Coldpressedoils';
import Condiments from '../../../../ui-components/Condiments';
import Flourscontentmobile from '../../../../ui-components/Flourscontentmobile';
import Snackscontentmobile from '../../../../ui-components/Snackscontentmobile';
import Pulsescontentmobile from '../../../../ui-components/Pulsescontentmobile';
import IndianSpicescontentmobile from '../../../../ui-components/IndianSpicescontentmobile';
import ColdpressedOilscontentmobile from '../../../../ui-components/ColdpressedOilscontentmobile';
import Condimentscontentmobile from '../../../../ui-components/Condimentscontentmobile';
import WhatkindofFoodMobile from '../../../../ui-components/WhatkindofFoodMobile';
import FloursTablet from '../../../../ui-components/FloursTablet';
import SnacksTablet from '../../../../ui-components/SnacksTablet';
import PulsesTablet from '../../../../ui-components/PulsesTablet';
import IndianspicesTablet from '../../../../ui-components/IndianspicesTablet';
import ColdpressesoilsTablet from '../../../../ui-components/ColdpressesoilsTablet';
import CondimentsTablet from '../../../../ui-components/CondimentsTablet';
import TabMenu from '../../../../ui-components/TabMenu';

const overrides = {
  Snacks: {
    backgroundImage: 'none',
  },
  Coldpressedoils: {
    backgroundImage: 'none',
  },
  Pulses: {
    backgroundImage: 'none',
  },
  Indianspices: {
    backgroundImage: 'none',
  },
  Condiments: {
    backgroundImage: 'none',
  },
  Flours: {
    backgroundImage: 'none',
  },
  ColdpressedOilsMobile: {
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  CondimentsMobile: {
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  FloursMobile: {
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  IndianspicesMobile: {
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  PulsesMobile: {
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  SnacksMobile: {
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  Heading: {
    style: {
      flexWrap: 'wrap',
    },
    flexWrap: 'wrap',
  },
  Stonemilledflours: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: 'none',
  },
  'Frame 509': {
    left: 'auto',
  },
  ColdpressedOilscontentmobile: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  Condimentscontentmobile: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  Flourscontentmobile: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  Indianspicescontentmobile: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  Pulsescontentmobile: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  Snackscontentmobile: {
    width: '100%',
    justifyContent: 'flex-start',
  },
  ColdpressesoilsTablet: {
    width: '100%',
    justifyContent: 'flex-start',
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  SnacksTablet: {
    width: '100%',
    justifyContent: 'flex-start',
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  CondimentsTablet: {
    width: '100%',
    justifyContent: 'flex-start',
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  FloursTablet: {
    width: '100%',
    justifyContent: 'flex-start',
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  IndianspicesTablet: {
    width: '100%',
    justifyContent: 'flex-start',
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  PulsesTablet: {
    width: '100%',
    justifyContent: 'flex-start',
    backgroundImage:
      'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
  },
  Slot: {
    width: '100%',
    overflowX: 'auto',
  },
  TabLabel48601961: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48601964: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48601965: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48601962: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48601960: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48601963: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48611809: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48611813: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48611814: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48611811: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48611810: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel48611812: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49041442: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49041446: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49041447: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49041444: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49041443: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49041445: {
    style: {
      cursor: 'pointer',
    },
  },
  // Indian Spices
  TabLabel49051505: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051509: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051510: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051507: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051506: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051508: {
    style: {
      cursor: 'pointer',
    },
  },
  // Cold Pressed Oils
  TabLabel49051572: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051576: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051577: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051574: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051573: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051575: {
    style: {
      cursor: 'pointer',
    },
  },
  // Condiments
  TabLabel49051626: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051630: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051631: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051628: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051627: {
    style: {
      cursor: 'pointer',
    },
  },
  TabLabel49051629: {
    style: {
      cursor: 'pointer',
    },
  },
  Button54171722: {
    backgroundColor: 'rgb(168, 104, 0)',
    className: KindOfFoodStyle.PrimaryButton,
  },
  Button54171731: {
    backgroundColor: 'rgb(168, 104, 0)',
    className: KindOfFoodStyle.PrimaryButton,
  },
  Button54181601: {
    backgroundColor: 'rgb(168, 104, 0)',
    className: KindOfFoodStyle.PrimaryButton,
  },
  Button54171717: {
    backgroundColor: 'rgb(168, 104, 0)',
    className: KindOfFoodStyle.PrimaryButton,
  },
  Button54181588: {
    backgroundColor: '#eff0f0',
  },
  Button54171735: {
    backgroundColor: '#eff0f0',
  },
  Button54181596: {
    backgroundColor: '#eff0f0',
  },
};

const KindOfFood = ({ kindOfFoodRef }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  let reactSwipeEl;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 600);
        setIsTablet(window.innerWidth >= 600 && window.innerWidth < 1440);
      };

      window.addEventListener('resize', handleResize);
      handleResize();
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  const [country, setCountry] = useState(null);

  useEffect(() => {
    const countryCode = Cookies.get('blCountry');
    if (countryCode) {
      setCountry(countryCode);
    }
  }, []);

  useEffect(() => {
    if (country && country !== 'IE') {
      setActiveSlide(1);
    }
  }, [country]);

  const renderComponent = (Component, ComponentTablet, index, name) => {
    return (
      <div
        className={classNames(KindOfFoodStyle.slide, {
          [KindOfFoodStyle.active]: activeSlide === index,
        })}
      >
        {isTablet ? (
          <ComponentTablet
            firstDot="static/img/firstDot.svg"
            secondDot="static/img/secondDot.svg"
            thirdDot="static/img/thirdDot.svg"
            fourthDot="static/img/fourthDot.svg"
            image={`static/img/${name}.png`}
            madeInIndiaImage="static/img/MadeInIndia.svg"
            overrides={overrides}
            isIreland={country && country === 'IE'}
            slot={
              <TabMenu
                isIreland={country && country === 'IE'}
                onFloursClick={() => setActiveSlide(0)}
                onSnacksClick={() => setActiveSlide(1)}
                onCondimentsClick={() => setActiveSlide(2)}
                onSpicesClick={() => setActiveSlide(3)}
                onPulsesClick={() => setActiveSlide(4)}
                onOilsClick={() => setActiveSlide(5)}
                activeTab={activeSlide}
                overrides={{
                  TabMenu: {
                    whiteSpace: 'nowrap',
                    justifyContent: 'center',
                    display: 'inline-flex',
                    width: '784px',
                  },
                  TabLabelMobile50831601: {
                    style: {
                      cursor: 'pointer',
                    },
                  },
                  TabLabelMobile50831605: {
                    style: {
                      cursor: 'pointer',
                    },
                  },
                  TabLabelMobile50831606: {
                    style: {
                      cursor: 'pointer',
                    },
                  },
                  TabLabelMobile50831603: {
                    style: {
                      cursor: 'pointer',
                    },
                  },
                  TabLabelMobile50831602: {
                    style: {
                      cursor: 'pointer',
                    },
                  },
                  TabLabelMobile50831604: {
                    style: {
                      cursor: 'pointer',
                    },
                  },
                }}
                width="100%"
              />
            }
          />
        ) : (
          <Component
            image={`static/img/${name}.png`}
            firstDot="static/img/firstDot.svg"
            secondDot="static/img/secondDot.svg"
            thirdDot="static/img/thirdDot.svg"
            fourthDot="static/img/fourthDot.svg"
            overrides={overrides}
            madeInIndiaImage="static/img/MadeInIndia.svg"
            onSnacksClick={() => setActiveSlide(1)}
            onCondimentsClick={() => setActiveSlide(2)}
            onSpicesClick={() => setActiveSlide(3)}
            onPulsesClick={() => setActiveSlide(4)}
            onOilsClick={() => setActiveSlide(5)}
            onFloursClick={() => setActiveSlide(0)}
            isIreland={country && country === 'IE'}
          />
        )}
      </div>
    );
  };

  const renderMobileComponent = (name, ComponentMobile) => {
    return (
      <ComponentMobile
        image={`static/img/${name}.png`}
        firstDot="static/img/firstDot.svg"
        secondDot="static/img/secondDot.svg"
        thirdDot="static/img/thirdDot.svg"
        fourthDot="static/img/fourthDot.svg"
        madeInIndiaImage="static/img/MadeInIndia.svg"
        overrides={overrides}
        sliderImage={
          <>
            <img
              src="static/img/Sliderleft.svg"
              alt="Sliderleft"
              className={KindOfFoodStyle.slideArrow}
              onClick={(e) => {
                e.preventDefault && e.preventDefault();
                e.stopPropagation && e.stopPropagation();
                reactSwipeEl.prev();
                setActiveSlide(activeSlide - 1);
              }}
            />
            <img
              src="static/img/Sliderright.svg"
              alt="Sliderright"
              className={KindOfFoodStyle.slideArrow}
              onClick={() => {
                reactSwipeEl.next();
                setActiveSlide(activeSlide + 1);
              }}
            />
          </>
        }
        onLeftClick={(e) => {
          e.preventDefault && e.preventDefault();
          e.stopPropagation && e.stopPropagation();
          reactSwipeEl.prev();
          setActiveSlide(activeSlide - 1);
        }}
        onRightClick={() => reactSwipeEl.next()}
        leftArrow="static/img/Sliderleft.svg"
        rightArrow="static/img/Sliderright.svg"
      />
    );
  };

  const tabMenuRef = useRef(null);
  useEffect(() => {
    if (tabMenuRef.current) {
      const selectedTab =
        tabMenuRef.current.querySelector('[state="Onselect"]');
      if (selectedTab) {
        const options =
          activeSlide === 5
            ? { behavior: 'smooth', block: 'nearest', inline: 'end' }
            : { behavior: 'smooth', inline: 'center' };
        selectedTab.scrollIntoView(options);
      }
    }
  }, [activeSlide]);

  return (
    <div className={KindOfFoodStyle.KindOfFoodParentDiv}>
      <div
        id="kindOfFood"
        className={KindOfFoodStyle.KindOfFood}
        style={{ height: isMobile ? 'auto' : isTablet ? '800px' : '900px' }}
        ref={kindOfFoodRef}
      >
        <div>
          {!isMobile ? (
            <>
              {country && country === 'IE'
                ? renderComponent(
                    Stonemilledflours,
                    FloursTablet,
                    0,
                    'StoneMilledFlours'
                  )
                : null}
              {renderComponent(Snacks, SnacksTablet, 1, 'Snacks')}
              {renderComponent(Condiments, CondimentsTablet, 2, 'Condiments')}
              {country && country === 'IE'
                ? renderComponent(
                    Indianspices,
                    IndianspicesTablet,
                    3,
                    'Indianspices'
                  )
                : null}
              {country && country === 'IE'
                ? renderComponent(Pulses, PulsesTablet, 4, 'Pulses')
                : null}
              {country && country === 'IE'
                ? renderComponent(
                    Coldpressedoils,
                    ColdpressesoilsTablet,
                    5,
                    'Coldpressedoils'
                  )
                : null}
            </>
          ) : (
            <WhatkindofFoodMobile
              overrides={{
                WhatkindofFoodMobile: {
                  width: '100%',
                  backgroundImage:
                    'linear-gradient(-180deg, rgba(255,255,255,1), rgba(245,217,188,1))',
                },
                Heading: {
                  style: {
                    flexWrap: 'wrap',
                  }
                },
                menu: {
                  width: '100%',
                },
              }}
              menu={
                <div
                  style={{ width: '100%', overflowX: 'auto' }}
                  ref={tabMenuRef}
                >
                  <TabMenu
                    isIreland={country && country === 'IE'}
                    onFloursClick={() => {
                      reactSwipeEl.slide(0);
                      setActiveSlide(0);
                    }}
                    onSnacksClick={() => {
                      reactSwipeEl.slide(country && country === 'IE' ? 1 : 0);
                      setActiveSlide(1);
                    }}
                    onCondimentsClick={() => {
                      reactSwipeEl.slide(country && country === 'IE' ? 2 : 1);
                      setActiveSlide(2);
                    }}
                    onSpicesClick={() => {
                      reactSwipeEl.slide(3);
                      setActiveSlide(3);
                    }}
                    onPulsesClick={() => {
                      reactSwipeEl.slide(4);
                      setActiveSlide(4);
                    }}
                    onOilsClick={() => {
                      reactSwipeEl.slide(5);
                      setActiveSlide(5);
                    }}
                    activeTab={activeSlide}
                    overrides={{
                      TabMenu: {
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        justifyContent: 'flex-start',
                        display: 'inline-flex',
                        width: '784px',
                      },
                    }}
                  />
                </div>
              }
              slot={
                <ReactSwipe
                  className="carousel"
                  swipeOptions={{ continuous: false, callback: setActiveSlide }}
                  ref={(el) => (reactSwipeEl = el)}
                >
                  {country && country === 'IE'
                    ? renderMobileComponent(
                        'StoneMilledFlours',
                        Flourscontentmobile,
                        0
                      )
                    : null}
                  {renderMobileComponent('Snacks', Snackscontentmobile, 1)}
                  {renderMobileComponent(
                    'Condiments',
                    Condimentscontentmobile,
                    2
                  )}
                  {country && country === 'IE'
                    ? renderMobileComponent(
                        'Indianspices',
                        IndianSpicescontentmobile,
                        3
                      )
                    : null}
                  {country && country === 'IE'
                    ? renderMobileComponent('Pulses', Pulsescontentmobile, 4)
                    : null}
                  {country && country === 'IE'
                    ? renderMobileComponent(
                        'Coldpressedoils',
                        ColdpressedOilscontentmobile,
                        5
                      )
                    : null}
                </ReactSwipe>
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default KindOfFood;
